var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import RadioButton from '../../../../components/RadioButton';
import styles from './UserSelect.module.scss';
import sharedStyles from '../../styles.module.scss';
const UserSelect = ({ users, redirectUrl, apiUrl }) => {
    const { t } = useTranslation();
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [accountUsers, setAccountUsers] = useState(users);
    const [isLoading, setLoading] = useState(false);
    const fetchLoggedInUser = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const currentUser = yield fetch(`${apiUrl}/api/user/details`);
            const currentUserData = yield currentUser.json();
            setAccountUsers((prevState) => [...prevState, currentUserData]);
        }
        catch (error) {
            console.error(error);
        }
    });
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        window.location.href = `${apiUrl}/user/switch/${selectedUserId}?redirect_url=${redirectUrl}`;
    };
    useEffect(() => {
        fetchLoggedInUser();
    }, []);
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit, className: sharedStyles.form }, { children: [_jsx("h4", Object.assign({ className: sharedStyles.title }, { children: t('authentication.userSelect.title') })), accountUsers.map((user) => (_jsx("div", Object.assign({ className: styles.userItem }, { children: _jsx(RadioButton, { name: "user radio", label: _jsx("span", Object.assign({ className: styles.fullname }, { children: user.fullname })), "data-cy": `user-select-radio-${user.id}`, checked: selectedUserId === user.id, handleOnChange: () => setSelectedUserId(user.id) }) }), user.id))), _jsx("div", Object.assign({ className: styles.submitButtonWrapper }, { children: _jsx(Button, Object.assign({ type: "submit", disabled: selectedUserId == null, "data-cy": "user-select-step-submit-button", isLoading: isLoading }, { children: t('authentication.userSelect.submit') })) }))] })));
};
export default UserSelect;
