import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import classnames from 'classnames';
import { useResponsiveness } from '../../../../../hooks/useResponsiveness';
import { getWordpressApiUrl } from '../../../../../utils/urls';
import LogoFull from '../../../../../public/icons/logo-full.svg';
import LogoSmall from '../../../../../public/icons/logo-small.svg';
import { applicationSpaRoutes } from '../../../../../constants';
import styles from './Logo.module.scss';
import { HeaderContext } from '../../../Header.context';
const Logo = () => {
    var _a;
    const responsiveness = useResponsiveness();
    const { user, urls, openedMenuItem } = useContext(HeaderContext);
    const isLoggedIn = (_a = user === null || user === void 0 ? void 0 : user.authenticated) !== null && _a !== void 0 ? _a : false;
    const applicationSpaUrl = urls === null || urls === void 0 ? void 0 : urls.applicationSpa;
    const isHeaderExtended = openedMenuItem != null;
    const logoElement = responsiveness.below.md ? _jsx(LogoSmall, { className: classnames(styles.logoIcon, styles.small) }) : _jsx(LogoFull, { className: styles.logoIcon });
    return (_jsx("a", Object.assign({ className: classnames(styles.logoLink, { [styles.headerExtended]: isHeaderExtended }), href: isLoggedIn ? `${applicationSpaUrl}${applicationSpaRoutes.myOverview}` : getWordpressApiUrl(), "aria-label": "Testaviva" }, { children: logoElement })));
};
export default Logo;
