import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import NotLoggedInHeader from './components/NotLoggedInHeader';
import LoggedInHeader from './components/LoggedInHeader';
import { HeaderContext } from '../../Header.context';
const DesktopHeader = () => {
    const { user } = useContext(HeaderContext);
    const headerElement = (user === null || user === void 0 ? void 0 : user.authenticated) ? (_jsx(LoggedInHeader, {})) : (_jsx(NotLoggedInHeader, {}));
    return headerElement;
};
export default DesktopHeader;
