import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { isHeaderUrlValid } from '../../../../../Header.utils';
import { HeaderContext } from '../../../../../Header.context';
import styles from './CategoryList.module.scss';
import cx from 'classnames';
const CategoryList = () => {
    var _a;
    const { categories, openedMenuItem, setOpenedMenuItem } = useContext(HeaderContext);
    const openedCategories = (_a = categories === null || categories === void 0 ? void 0 : categories.find((category) => category.title === openedMenuItem)) === null || _a === void 0 ? void 0 : _a.child_items;
    if (openedCategories == null)
        return null;
    return (_jsx("div", Object.assign({ className: styles.listWrapper, onMouseLeave: () => setOpenedMenuItem(null) }, { children: _jsx("div", Object.assign({ className: cx('container', styles.list) }, { children: _jsx("div", Object.assign({ className: styles.listContent }, { children: openedCategories.map((category) => {
                    var _a;
                    return (_jsxs("div", Object.assign({ className: styles.category }, { children: [isHeaderUrlValid(category.url) ? (_jsx("a", Object.assign({ href: category.url, className: cx(styles.categoryTitle, styles.link), "data-cy": `list-category-title-${category.ID}` }, { children: _jsx("span", Object.assign({ className: styles.linkText }, { children: category.title })) }))) : (_jsx("p", Object.assign({ className: styles.categoryTitle, "data-cy": `list-category-title-${category.ID}` }, { children: category.title }))), category.child_items != null && category.child_items.length > 0 ? (_jsx("ul", Object.assign({ className: styles.categoryItems }, { children: (_a = category.child_items) === null || _a === void 0 ? void 0 : _a.map((children) => (_jsx("li", Object.assign({ className: styles.categoryItem }, { children: _jsx("a", Object.assign({ href: children.url, "aria-label": children.title, className: styles.categoryItemLink, "data-cy": `list-category-item-${children.ID}` }, { children: children.title })) }), children.title))) }))) : null] }), category.title));
                }) })) })) })));
};
export default CategoryList;
