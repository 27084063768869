var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useCallback, cloneElement, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useDisablePageScrolling } from '../../hooks/useDisablePageScrolling';
import styles from './Drawer.module.scss';
import { defaultOpacity, defaultWidth, defaultDurationS } from './Drawer.constants';
const Drawer = forwardRef((_a, ref) => {
    var _b, _c;
    var { open, onClose = null, children, config, header, classNames = {
        overlay: undefined,
        scrollArea: undefined
    }, drawerExtraProps = {
        overlay: {},
        wrapper: {},
        scrollArea: {}
    }, disableDocumentScrolling = true } = _a, props = __rest(_a, ["open", "onClose", "children", "config", "header", "classNames", "drawerExtraProps", "disableDocumentScrolling"]);
    const [isOpen, setOpen] = useState(open);
    const [isUserSelectDisabled, setUserSelectDisabled] = useState(false);
    const [isDraggable, setDraggable] = useState(false);
    const { width: windowWidth } = useWindowSize();
    const drawerWidth = (() => {
        var _a;
        const width = (_a = config === null || config === void 0 ? void 0 : config.width) !== null && _a !== void 0 ? _a : defaultWidth;
        if (windowWidth < width || windowWidth <= 480) {
            return windowWidth;
        }
        return width;
    })();
    const overlayOpacity = (_b = config === null || config === void 0 ? void 0 : config.opacity) !== null && _b !== void 0 ? _b : defaultOpacity;
    const animationS = (_c = config === null || config === void 0 ? void 0 : config.animationDuration) !== null && _c !== void 0 ? _c : defaultDurationS;
    const toggleDrawer = useCallback((openValue, runCloseCallback = false) => {
        if (openValue) {
            setOpen(true);
        }
        else {
            setOpen(false);
            if (runCloseCallback && typeof onClose === 'function') {
                onClose();
            }
        }
    }, [onClose]);
    useDisablePageScrolling(isOpen, disableDocumentScrolling);
    useEffect(() => {
        toggleDrawer(open);
    }, [open]);
    useEffect(() => {
        if (typeof onClose !== 'function') {
            toggleDrawer(isOpen);
        }
    }, [isOpen, onClose]);
    const headerElement = header != null ? cloneElement(header, Object.assign(Object.assign({}, header.props), { className: styles.header }), header.props.children) : null;
    return createPortal((_jsxs(_Fragment, { children: [_jsx(AnimatePresence, Object.assign({ mode: "wait" }, { children: isOpen ? (_jsx(motion.div, Object.assign({}, drawerExtraProps.overlay, { className: classnames(styles.overlay, classNames.overlay), initial: { opacity: 0 }, animate: { opacity: overlayOpacity }, exit: { opacity: 0 }, onClick: () => toggleDrawer(false, true), "data-cy": "drawer-overlay" }))) : null })), _jsx(AnimatePresence, Object.assign({ mode: "wait" }, { children: isOpen ? (_jsxs(motion.div, Object.assign({}, drawerExtraProps.wrapper, props, { className: classnames(styles.drawer, { [styles.disableSelect]: isUserSelectDisabled }), initial: { x: drawerWidth, right: 0 }, animate: { x: 0 }, exit: { x: drawerWidth, right: -drawerWidth }, transition: { duration: animationS, ease: 'easeInOut' }, onAnimationComplete: () => setDraggable(true), style: { width: drawerWidth }, drag: isDraggable ? 'x' : false, dragConstraints: { left: 0, right: drawerWidth }, dragElastic: 0, dragSnapToOrigin: true, dragTransition: { bounceStiffness: 600, bounceDamping: 150 }, onDrag: () => {
                        if (!isUserSelectDisabled) {
                            setUserSelectDisabled(true);
                        }
                    }, onDragEnd: (_, info) => {
                        setUserSelectDisabled(false);
                        if (info.offset.x > drawerWidth / 2) {
                            setOpen(false);
                            if (typeof onClose === 'function') {
                                onClose();
                            }
                        }
                    } }, { children: [headerElement, _jsx("div", Object.assign({}, drawerExtraProps.scrollArea, { className: classnames(styles.drawerScroll, classNames.scrollArea), style: { touchAction: 'pan-y' }, ref: ref }, { children: children }))] }))) : null }))] })), document.body);
});
Drawer.displayName = 'Drawer';
export default Drawer;
