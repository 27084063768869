import { createSlice } from '@reduxjs/toolkit';
const initialState = { isOpen: false };
const sidebarSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        setPurchaseSidebarOpen(state, action) {
            state.isOpen = action.payload;
        }
    }
});
export const { setPurchaseSidebarOpen } = sidebarSlice.actions;
export default sidebarSlice.reducer;
