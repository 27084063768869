import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useEventListener } from '../../hooks/useEventListener';
import { useWindowSize } from '../../hooks/useWindowSize';
import styles from './Tooltip.module.scss';
import { motion } from 'framer-motion';
const Tooltip = ({ parentRef, position = 'bottom-right', onClose, classNames, isArrowVisible = false, isParentSticky = false, children }) => {
    const [popupPosition, setPopupPosition] = useState(position);
    const tooltipRef = useRef(null);
    const arrowRef = useRef(null);
    const { width } = useWindowSize();
    const handleCloseTooltip = () => {
        if (onClose != null)
            onClose();
    };
    useClickOutside(tooltipRef, handleCloseTooltip, parentRef);
    useEventListener('resize', () => calculateTooltipPosition(popupPosition, width));
    useEventListener('resize', calculateArrowPosition);
    useEffect(() => {
        calculateTooltipPosition(popupPosition, width);
    }, [popupPosition, width]);
    useEffect(() => {
        if (isArrowVisible) {
            calculateArrowPosition();
        }
    }, [isArrowVisible]);
    return createPortal(_jsxs(motion.div, Object.assign({ ref: tooltipRef, className: cx(styles.wrapper, classNames === null || classNames === void 0 ? void 0 : classNames.wrapper, {
            [styles.sticky]: isParentSticky
        }), initial: { opacity: 0 }, transition: { delay: 0.01, duration: 0.1 }, animate: { opacity: 1 } }, { children: [isArrowVisible ? (_jsx(motion.div, { ref: arrowRef, className: cx(styles.arrow, classNames === null || classNames === void 0 ? void 0 : classNames.arrow, {
                    [styles.arrowOnTop]: isArrowVisible && position.includes('bottom')
                }), initial: { opacity: 0 }, transition: { delay: 0.01, duration: 0.1 }, animate: { opacity: 1 } })) : null, children] })), document.body);
    function calculateArrowPosition() {
        setTimeout(() => {
            var _a, _b;
            const parentElementRect = (_a = parentRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            const tooltipElementRect = (_b = tooltipRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
            if (parentElementRect != null && arrowRef.current != null && tooltipElementRect != null) {
                arrowRef.current.style.left = `${parentElementRect.left - tooltipElementRect.left + (parentElementRect.width / 2) - 10}px`;
            }
        }, 10);
    }
    function calculateTooltipPosition(currentPosition, windowWidth) {
        var _a, _b;
        if (width <= 0)
            return;
        const parentElementRect = (_a = parentRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const tooltipRect = (_b = tooltipRef === null || tooltipRef === void 0 ? void 0 : tooltipRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
        if (tooltipRect != null && parentElementRect != null) {
            const verticalGap = isArrowVisible ? 15 : 5;
            const horizontalGap = 24;
            let top = verticalGap + (!isParentSticky ? window.scrollY : 0);
            let left = 0;
            if (currentPosition === 'bottom-right') {
                top += parentElementRect.top + parentElementRect.height;
                left += parentElementRect.left;
                if (left + tooltipRect.width > windowWidth) {
                    setPopupPosition('bottom-left');
                    return;
                }
            }
            else if (currentPosition === 'bottom-left') {
                top += parentElementRect.top + parentElementRect.height;
                left += parentElementRect.left - tooltipRect.width + parentElementRect.width + 0;
            }
            else if (currentPosition === 'bottom-center') {
                top += parentElementRect.top + parentElementRect.height;
                left += parentElementRect.left - (tooltipRect.width / 2) + (parentElementRect.width / 2);
                if (left + tooltipRect.width >= windowWidth - horizontalGap) {
                    left = windowWidth - tooltipRect.width - horizontalGap;
                }
                else if (left <= 0) {
                    left = horizontalGap;
                }
            }
            else if (currentPosition === 'top-right') {
                top += parentElementRect.top - tooltipRect.height - verticalGap;
                left += parentElementRect.left;
                if (left + tooltipRect.width > windowWidth) {
                    setPopupPosition('bottom-left');
                    return;
                }
            }
            else if (currentPosition === 'top-left') {
                top += parentElementRect.top - tooltipRect.height - verticalGap;
                left += parentElementRect.left - tooltipRect.width + parentElementRect.width;
            }
            if (tooltipRef.current != null) {
                tooltipRef.current.style.top = `${top}px`;
                tooltipRef.current.style.left = `${left}px`;
            }
        }
    }
};
export default Tooltip;
