import { useEffect, useState } from 'react';
export function useFetchPartners(fetchPartnerPromise) {
    const [partners, setPartners] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const fetchPartner = () => {
        if (isLoading || fetchPartnerPromise == null)
            return;
        setLoading(true);
        fetchPartnerPromise()
            .then((data) => setPartners(data))
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        if (partners == null) {
            fetchPartner();
        }
    }, [partners]);
    return { data: partners, isLoading, isError };
}
