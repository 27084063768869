var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
const IconButton = (_a) => {
    var { iconClassNames, title, icon, children } = _a, props = __rest(_a, ["iconClassNames", "title", "icon", "children"]);
    return (_jsxs("button", Object.assign({}, props, { type: "button", title: title }, { children: [_jsx(FontAwesomeIcon, { className: classnames(iconClassNames), icon: icon }), children != null ? children : null] })));
};
export default IconButton;
