import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { applicationUrl } from '@constants';
import { authHeaderName } from '@frontend-components/constants';
import { getAuthToken } from '@frontend-components/utils/auth';
export const USER_API_REDUCER_KEY = 'userApi';
export const userApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: applicationUrl,
        prepareHeaders: (headers) => {
            const authToken = getAuthToken();
            if (authToken != null && authToken && authToken.length > 0) {
                headers.set(authHeaderName, authToken);
            }
            return headers;
        }
    }),
    reducerPath: USER_API_REDUCER_KEY,
    endpoints: (builder) => ({
        getUserDetails: builder.query({
            query: () => ({
                url: '/api/user/details',
                method: 'GET'
            })
        })
    })
});
export const { useGetUserDetailsQuery } = userApi;
