var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidEmail } from '../../../../utils/validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Message from '../../../../components/Message';
import sharedStyles from '../../styles.module.scss';
import styles from './ResetPassword.module.scss';
import { AuthenticationSteps } from '../../constants';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
const ResetPassword = ({ onSwitchStep, onSetLoading, apiUrl, email }) => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [resetPasswordEmail, setResetPasswordEmail] = useState(email);
    const [isError, setError] = useState(false);
    const [isResetPasswordError, setResetPasswordError] = useState(false);
    const [isResetEmailSent, setResetEmailSent] = useState(false);
    const handleSubmit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        if (isLoading)
            return;
        if (!isValidEmail(email)) {
            setError(true);
            return;
        }
        setLoading(true);
        setError(false);
        setResetPasswordError(false);
        if (onSetLoading != null)
            onSetLoading(true);
        try {
            const response = yield fetch(`${apiUrl}/resetting/send-email`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: email }),
                credentials: 'include'
            });
            if (response.ok) {
                setResetEmailSent(true);
            }
            else {
                setResetPasswordError(true);
                setLoading(false);
            }
            if (onSetLoading != null)
                onSetLoading(false);
        }
        catch (_a) {
            setResetPasswordError(true);
            setLoading(false);
            if (onSetLoading != null)
                onSetLoading(false);
        }
    });
    const errorMessageElement = isResetPasswordError ? (_jsx("div", Object.assign({ className: sharedStyles.formItem }, { children: _jsx(Message, Object.assign({ "data-cy": "reset-password-step-generic-error", type: "error" }, { children: t('global.errors.genericError') })) }))) : null;
    if (isResetEmailSent) {
        return (_jsxs("div", Object.assign({ className: styles.resetSuccess }, { children: [_jsx("h2", { children: t('authentication.resetPassword.success.title') }), _jsx("p", { children: t('authentication.resetPassword.success.p1') }), _jsx("p", { children: t('authentication.resetPassword.success.p2') }), _jsx("p", { children: t('authentication.resetPassword.success.p3') })] })));
    }
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit, className: sharedStyles.form, "data-cy": "reset-password-step" }, { children: [_jsx("h4", Object.assign({ className: sharedStyles.title }, { children: t('authentication.resetPassword.title') })), _jsx("div", Object.assign({ className: sharedStyles.formItem }, { children: _jsx(Input, { type: "email", name: "email", label: t('authentication.resetPassword.emailLabel'), isError: isError, errorMessage: isError ? t('global.errors.email.invalid') : null, value: resetPasswordEmail, onChange: (event) => setResetPasswordEmail(event.target.value), "data-cy": "reset-password-step-email-input", disabled: isLoading, autoFocus: true }) })), errorMessageElement, _jsxs("div", Object.assign({ className: sharedStyles.formNavigationButtons }, { children: [_jsx(Button, Object.assign({ onClick: () => onSwitchStep(AuthenticationSteps.EMAIL_VERIFICATION), kind: "text", size: "fluid", icon: _jsx(FontAwesomeIcon, { icon: faArrowLeft, className: sharedStyles.buttonIcon }), "data-cy": "reset-password-step-back-button" }, { children: t('authentication.backCta') })), _jsx(Button, Object.assign({ type: "submit", color: "pink", "data-cy": "reset-password-step-submit-button", isLoading: isLoading, size: "fluid" }, { children: t('authentication.resetPassword.submit') }))] }))] })));
};
export default ResetPassword;
