import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { applicationUrl } from '@constants';
import { authHeaderName } from '@frontend-components/constants';
import { getAuthToken } from '@frontend-components/utils/auth';
export const BASKET_API_REDUCER_KEY = 'basketApi';
export const basketApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: applicationUrl,
        prepareHeaders: (headers) => {
            const authToken = getAuthToken();
            if (authToken != null && authToken && authToken.length > 0) {
                headers.set(authHeaderName, authToken);
            }
            return headers;
        }
    }),
    reducerPath: BASKET_API_REDUCER_KEY,
    tagTypes: ['BasketItems'],
    endpoints: (builder) => ({
        getBasket: builder.query({
            query: () => ({
                url: `${applicationUrl}/api/basket`,
                method: 'GET'
            }),
            providesTags: ['BasketItems']
        }),
        postBasketItem: builder.mutation({
            query: (body) => ({
                url: `${applicationUrl}/api/basket`,
                method: 'POST',
                body,
                credentials: 'include'
            }),
            invalidatesTags: ['BasketItems']
        }),
        putBasketItem: builder.mutation({
            query: ({ id, purchase_type }) => ({
                url: `${applicationUrl}/api/basket/${id}`,
                method: 'PUT',
                body: { purchase_type }
            }),
            invalidatesTags: ['BasketItems']
        }),
        deleteBasketItem: builder.mutation({
            query: (id) => ({
                url: `${applicationUrl}/api/basket/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['BasketItems']
        }),
        getBasketPartnerText: builder.query({
            query: () => ({
                url: `${applicationUrl}/api/basket/partner-text`,
                method: 'GET'
            })
        })
    })
});
export const { useGetBasketQuery, usePostBasketItemMutation, usePutBasketItemMutation, useDeleteBasketItemMutation, useGetBasketPartnerTextQuery } = basketApi;
