import { configureStore } from '@reduxjs/toolkit';
import { userApi } from '@store/api/user';
import { paymentApi } from '@store/api/payment';
import { productsApi } from '@store/api/products';
import { basketApi } from '@store/api/basket';
import { partnerApi } from '@store/api/partner';
import rootReducer from './rootReducer';
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
        userApi.middleware,
        paymentApi.middleware,
        productsApi.middleware,
        basketApi.middleware,
        partnerApi.middleware
    ])
});
export default store;
