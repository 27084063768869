export var AuthenticationSteps;
(function (AuthenticationSteps) {
    AuthenticationSteps["EMAIL_VERIFICATION"] = "EMAIL_VERIFICATION";
    AuthenticationSteps["ONBOARD_WITH_INFO"] = "ONBOARD_WITH_INFO";
    AuthenticationSteps["USER_SELECT"] = "USER_SELECT";
    AuthenticationSteps["LOGIN"] = "LOGIN";
    AuthenticationSteps["RESET_PASSWORD"] = "RESET_PASSWORD";
    AuthenticationSteps["USER_INFO"] = "USER_INFO";
    AuthenticationSteps["PARTNER_SELECT"] = "PARTNER_SELECT";
    AuthenticationSteps["CONSENTS"] = "CONSENTS";
    AuthenticationSteps["VALIDATION"] = "VALIDATION";
})(AuthenticationSteps || (AuthenticationSteps = {}));
export const nonePartnerId = 'none';
export var SubmitActions;
(function (SubmitActions) {
    SubmitActions["LOGIN"] = "LOGIN";
    SubmitActions["RESET_PASSWORD"] = "RESET_PASSWORD";
    SubmitActions["CREATE_USER"] = "CREATE_USER";
})(SubmitActions || (SubmitActions = {}));
export const loginRedirectKey = 'login_redirect';
