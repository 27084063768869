var SupportedCurrencies;
(function (SupportedCurrencies) {
    SupportedCurrencies["DKK"] = "DKK";
})(SupportedCurrencies || (SupportedCurrencies = {}));
const currenciesLocale = { DKK: 'da-DK' };
export function formatPrice(amount, currency = 'DKK') {
    if (amount == null)
        return '';
    const formatter = new Intl.NumberFormat(currenciesLocale[currency], {
        style: 'currency',
        currency
    });
    return formatter.format(amount);
}
