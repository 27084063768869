export function isHeaderUrlValid(urlString) {
    if (urlString == null || urlString.length === 0) {
        return false;
    }
    try {
        const url = new URL(urlString);
        if (url.host === 'none' || url.host === '/') {
            return false;
        }
        return true;
    }
    catch (_a) {
        return false;
    }
}
export function getDropdownCoordinates(ref) {
    if (ref.current == null) {
        return { left: 0, top: 0, width: 0 };
    }
    const switchButtonRect = ref.current.getBoundingClientRect();
    return {
        left: switchButtonRect.left,
        width: switchButtonRect.width,
        top: switchButtonRect.top + switchButtonRect.height
    };
}
