import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { AuthenticationSteps } from '../../constants';
import styles from '../../styles.module.scss';
const Validation = ({ onSwitchStep }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: styles.form, "data-cy": "validation-step" }, { children: [_jsx("p", Object.assign({ className: styles.title }, { children: t('authentication.validation.text') })), _jsx("div", Object.assign({ className: styles.formOtherActions }, { children: _jsxs("p", { children: [t('authentication.signupText'), ' ', _jsx("a", Object.assign({ href: "", onClick: (event) => {
                                event.preventDefault();
                                onSwitchStep(AuthenticationSteps.LOGIN);
                            }, "data-cy": "validation-step-signup-button" }, { children: t('authentication.signupLink') }))] }) }))] })));
};
export default Validation;
