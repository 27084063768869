import { PurchaseTypes } from '../../constants';
import { isProductDigital } from '../../utils/products';
export function extendRecommendedProductsResponse(productData) {
    return productData.map((product) => {
        if (!isProductDigital(product)) {
            return { product, purchase_type: PurchaseTypes.digital_document_advice };
        }
        return { product, purchase_type: PurchaseTypes.digital_document_basis };
    });
}
export function scrollTop(ref) {
    if (ref.current == null) {
        return;
    }
    ref.current.scrollTo(0, 0);
}
