import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { formatPrice } from '../../../../utils/formatPrice';
import { getPriceSetProductPrice } from '../../../../utils/prices';
import { getProductEligiblePurchaseTypes } from '../../../../utils/products';
import { Select, Option } from '../../../../components/Select';
import Button from '../../../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import styles from './SidebarBasketItem.module.scss';
const SidebarBasketItem = ({ item, onAdd, onDelete, priceSet, onPurchaseTypeChange, isLoading = false, identifier = '' }) => {
    const { t } = useTranslation();
    const isEditDisable = (item === null || item === void 0 ? void 0 : item.is_editable) != null && !item.is_editable;
    const { current: currentPrice, origin: originPrice } = getPriceSetProductPrice({ priceSet, product: item.product, purchaseType: item.purchase_type });
    const handleChangePurchaseType = (purchaseType) => {
        if (isEditDisable)
            return;
        if (purchaseType.length === 0 || purchaseType === item.purchase_type || isLoading)
            return;
        onPurchaseTypeChange(item.product, purchaseType, item === null || item === void 0 ? void 0 : item.id);
    };
    const deleteButtonElement = typeof onDelete === 'function' && !isEditDisable ? (_jsx("button", Object.assign({ type: "button", title: "Delete", onClick: () => onDelete(item.id), className: styles.itemTopDeleteButton, "data-testid": "purchase-sidebar-delete-item-button" }, { children: _jsx(FontAwesomeIcon, { className: styles.itemTopDeleteButton, icon: faTimes }) }))) : null;
    const purchaseTypeSelectOptions = getProductEligiblePurchaseTypes(item).map((option) => (_jsx(Option, Object.assign({ value: option, "data-gtm": `PurchaseSidebar-${option}` }, { children: t(`global.purchaseTypes.${option}`) }), option)));
    const addProductButton = typeof onAdd === 'function' ? (_jsxs(Button, Object.assign({ title: "Add", onClick: () => onAdd(item.product, item.purchase_type), className: styles.itemAddButton, kind: "text", size: "small", "data-testid": "purchase-sidebar-add-item-button" }, { children: ["+", ' ', t('sidebar.addProduct')] }))) : null;
    const priceElement = (item === null || item === void 0 ? void 0 : item.amount) != null ? (_jsxs("div", Object.assign({ className: styles.price }, { children: [item.discount != null && item.discount > 0 ? _jsx("p", Object.assign({ className: styles.priceOld }, { children: formatPrice(item.amount) })) : null, _jsx("p", Object.assign({ className: styles.priceNew }, { children: item.discount != null ? formatPrice(item.amount - item.discount) : null }))] }))) : null;
    return (_jsxs("div", Object.assign({ "data-cy": `sidebar-${identifier}-item-${item.product}`, className: styles.item }, { children: [_jsxs("div", Object.assign({ className: styles.itemTop }, { children: [_jsx("h3", Object.assign({ className: cx(styles.itemTopTitle, { [styles.disabled]: isEditDisable }) }, { children: t(`global.products.${item.product}`) })), deleteButtonElement] })), !isLoading ? (_jsx(Select, Object.assign({ name: "product-type-selector", kind: "underline", inputSize: "small", value: item.purchase_type, onChange: (purchaseType) => handleChangePurchaseType(purchaseType), isNative: true, "data-testid": `purchase-sidebar-change-purchase-type-${item.purchase_type}`, disabled: isEditDisable || isLoading }, { children: purchaseTypeSelectOptions }))) : null, addProductButton != null ? (_jsxs("div", Object.assign({ className: styles.recommendedProductPriceWrapper }, { children: [addProductButton, _jsxs("div", Object.assign({ className: cx(styles.price, styles.recommended) }, { children: [originPrice > currentPrice ? (_jsx("p", Object.assign({ className: styles.priceOld }, { children: formatPrice(originPrice) }))) : null, formatPrice(currentPrice > 0 ? currentPrice : originPrice)] }))] }))) : null, priceElement] })));
};
export default SidebarBasketItem;
