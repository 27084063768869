var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';
import { useDisablePageScrolling } from '../../hooks/useDisablePageScrolling';
import { useResponsiveness } from '../../hooks/useResponsiveness';
import Logo from './components/HeaderElements/Logo';
import DesktopHeader from './components/DesktopHeader';
import MobileHeader from './components/MobileHeader';
import styles from './Header.module.scss';
import { wordPressApiUrl } from '../../constants';
import { HeaderContext } from './Header.context';
const Header = (_a) => {
    var { user, notifications, isUserLoading = false, actions, urls = { application: '', applicationSpa: '' }, basketProductsCount, redirectTo } = _a, props = __rest(_a, ["user", "notifications", "isUserLoading", "actions", "urls", "basketProductsCount", "redirectTo"]);
    const [openedMenuItem, setOpenedMenuItem] = useState(null);
    const [isWordpressEnabled, setWordpressEnabled] = useState(false);
    const [categories, setCategories] = useState();
    const headerRef = useRef(null);
    useDisablePageScrolling(openedMenuItem != null);
    const responsiveness = useResponsiveness();
    const handleKeydown = (event) => {
        if (event.code === 'Escape') {
            setOpenedMenuItem(null);
        }
    };
    const handleClickCategory = (name) => {
        if (name === openedMenuItem) {
            setOpenedMenuItem(null);
        }
        else {
            setOpenedMenuItem(name);
        }
    };
    useEffect(() => {
        fetchCategories();
        document.addEventListener('keydown', handleKeydown);
        // wp hack
        const wpHeaderElement = document.querySelector('#wpadminbar');
        if (wpHeaderElement != null) {
            setWordpressEnabled(true);
        }
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, []);
    const contextValue = useMemo(() => ({
        user,
        notifications,
        isUserLoading,
        actions,
        urls,
        basketProductsCount,
        redirectTo,
        categories,
        openedMenuItem,
        setOpenedMenuItem,
        handleClickCategory,
        headerRef
    }), [user, notifications, isUserLoading, actions, urls, basketProductsCount, redirectTo, categories, openedMenuItem, handleClickCategory]);
    return (_jsxs(HeaderContext.Provider, Object.assign({ value: contextValue }, { children: [_jsx("div", Object.assign({}, props, { id: "testaviva-header", className: cx(styles.wrapper, {
                    [styles.extended]: openedMenuItem != null && (categories === null || categories === void 0 ? void 0 : categories.some((category) => category.title === openedMenuItem)),
                    [styles.wpEnabled]: isWordpressEnabled
                }), ref: headerRef }, { children: _jsxs("div", Object.assign({ className: cx('container', styles.contentCenter) }, { children: [_jsx(Logo, {}), !isUserLoading ? (_jsx("div", Object.assign({ className: styles.rightContent }, { children: responsiveness.below.md ? (_jsx(MobileHeader, {})) : (_jsx(DesktopHeader, {})) }))) : null] })) })), _jsx("div", { className: styles.headerGap })] })));
    function fetchCategories() {
        fetch(`${wordPressApiUrl}/wp-json/menus/v1/menus/menu-main`)
            .then((data) => data.json())
            .then((data) => setCategories(data.items));
    }
};
export default Header;
