import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthenticationModalData } from '@store/slices/modalsSlice';
import { PurchaseTypes } from '@frontend-components/constants';
import { AuthenticationSteps } from '@frontend-components/features/Authentication';
const supportedPurchaseTypes = [PurchaseTypes.digital_document_advice, PurchaseTypes.digital_document_review, PurchaseTypes.digital_document_basis];
const PurchaseButton = ({ selectedProduct = '', element, purchaseType = PurchaseTypes.digital_document_advice }) => {
    const dispatch = useDispatch();
    const handleClickOpenAuthModal = () => {
        if (selectedProduct.length === 0)
            return;
        dispatch(setAuthenticationModalData({
            productType: selectedProduct,
            purchaseType: supportedPurchaseTypes.includes(purchaseType) ? purchaseType : PurchaseTypes.digital_document_advice,
            step: AuthenticationSteps.EMAIL_VERIFICATION
        }));
    };
    useEffect(() => {
        element.addEventListener('click', handleClickOpenAuthModal);
        return () => {
            element.removeEventListener('click', handleClickOpenAuthModal);
        };
    }, []);
    return null;
};
export default PurchaseButton;
