import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { I18nextProvider } from 'react-i18next';
import { createRoot } from 'react-dom/client';
import { createPortal } from 'react-dom';
import ErrorBoundary from '@frontend-components/components/ErrorBoundary';
import HeaderFeature from './components/Header';
import PurchaseSidebar from './components/PurchaseSidebar';
import AuthenticationBookingModal from './components/AuthenticationBookingModal';
import PurchaseButton from './components/PurchaseButton';
import { Provider } from 'react-redux';
import appStore from './store';
import i18n from '../i18n';
import { PurchaseTypes } from '@frontend-components/constants';
const ModulesWithStore = () => {
    const pageHeaderElement = document.querySelector('.elementor-location-header');
    if (pageHeaderElement != null) {
        pageHeaderElement.innerHTML = '';
        pageHeaderElement.classList.add('visible');
    }
    const modalsElement = createElement('modals');
    const purchaseSidebarElement = createElement('purchase-sidebar');
    const bookingButtons = document.querySelectorAll('[href*="#book"]');
    return (_jsx(Provider, Object.assign({ store: appStore }, { children: _jsxs(I18nextProvider, Object.assign({ i18n: i18n }, { children: [modalsElement ? (_jsx(ErrorBoundary, { children: createPortal(_jsx(AuthenticationBookingModal, {}), modalsElement) })) : null, pageHeaderElement ? (_jsx(ErrorBoundary, { children: createPortal(_jsx(HeaderFeature, {}), pageHeaderElement) })) : null, purchaseSidebarElement ? _jsx(ErrorBoundary, { children: createPortal(_jsx(PurchaseSidebar, {}), purchaseSidebarElement) }) : null, [...bookingButtons].map((button, key) => {
                    var _a, _b, _c;
                    const productData = (_a = button.getAttribute('href')) === null || _a === void 0 ? void 0 : _a.replace('#book-', '').split('+');
                    return (_jsx(ErrorBoundary, { children: createPortal(_jsx(PurchaseButton, { element: button, selectedProduct: (_b = productData === null || productData === void 0 ? void 0 : productData[0]) !== null && _b !== void 0 ? _b : '', purchaseType: (_c = productData === null || productData === void 0 ? void 0 : productData[1]) !== null && _c !== void 0 ? _c : PurchaseTypes.digital_document_advice }), button) }, `BOOKING_BUTTON_${key}`));
                })] })) })));
};
function createElement(id) {
    var _a;
    const div = document.createElement('div');
    div.id = id;
    (_a = document.querySelector('body')) === null || _a === void 0 ? void 0 : _a.appendChild(div);
    return div;
}
export const renderModulesWithStore = () => {
    setTimeout(() => {
        const container = createElement('modules-with-store');
        if (container != null) {
            const root = createRoot(container);
            root.render(_jsx(ModulesWithStore, {}));
        }
    }, 200);
};
renderModulesWithStore();
