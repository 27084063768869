import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { applicationUrl } from '@constants';
export const PARTNER_API_REDUCER_KEY = 'partnerApi';
export const partnerApi = createApi({
    reducerPath: PARTNER_API_REDUCER_KEY,
    baseQuery: fetchBaseQuery({ baseUrl: applicationUrl }),
    endpoints: (builder) => ({
        getPartners: builder.query({
            query: () => ({
                url: '/api/partners?priority=1',
                method: 'GET'
            })
        })
    })
});
export const { useLazyGetPartnersQuery } = partnerApi;
