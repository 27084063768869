import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidEmail } from '../../../../utils/validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Message from '../../../../components/Message';
import { AuthenticationSteps } from '../../constants';
import sharedStyles from '../../styles.module.scss';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
const EmailVerification = ({ email, apiUrl, onSubmit, onSwitchStep }) => {
    const { t } = useTranslation();
    const [verificationEmail, setVerificationEmail] = useState(email);
    const [isEmailError, setEmailError] = useState(false);
    const [requestError, setRequestError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        setEmailError(false);
        setRequestError(null);
        if (!isValidEmail(verificationEmail)) {
            setEmailError(true);
            return;
        }
        setLoading(true);
        fetch(`${apiUrl}/api/user/check-email?email=${encodeURIComponent(verificationEmail)}`)
            .then((response) => {
            if (response.ok) {
                onSubmit({ nextStep: AuthenticationSteps.LOGIN, email: verificationEmail });
                return;
            }
            if (response.status === 404) {
                onSubmit({ nextStep: AuthenticationSteps.USER_INFO, email: verificationEmail });
                return;
            }
            if (response.status === 429) {
                onSwitchStep(AuthenticationSteps.VALIDATION);
                return;
            }
            setRequestError(t('global.errors.genericError'));
        })
            .catch(() => setRequestError(t('global.errors.genericError')))
            .finally(() => setLoading(false));
    };
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit, className: sharedStyles.form, "data-cy": "email-verification-step" }, { children: [_jsx("h4", Object.assign({ className: sharedStyles.title }, { children: t('authentication.emailVerification.title') })), _jsx("div", Object.assign({ className: sharedStyles.formItem }, { children: _jsx(Input, { type: "email", name: "email", autoComplete: "username", label: t('authentication.login.emailLabel'), value: verificationEmail, onChange: (event) => setVerificationEmail(event.target.value), isError: isEmailError, errorMessage: isEmailError ? t('global.errors.email.invalid') : null, "data-cy": "email-verification-step-email", disabled: isLoading, rightIcon: _jsx(FontAwesomeIcon, { icon: faCheckCircle, className: cx(sharedStyles.emailCheckIcon, 'text-grey') }), autoFocus: true }) })), requestError != null && _jsx(Message, Object.assign({ "data-cy": "", className: "my-3", type: "error" }, { children: requestError })), _jsx(Button, Object.assign({ size: "fluid", color: "pink", type: "submit", "data-cy": "email-verification-step-submit", isLoading: isLoading }, { children: t('authentication.emailVerification.submit') }))] })));
};
export default EmailVerification;
