import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import Message from '../Message';
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined
        };
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(error) {
        this.setState({ error });
    }
    render() {
        const { hasError, error } = this.state;
        const { fallbackComponent, hideErrorMessage, children } = this.props;
        if (hasError) {
            if (hideErrorMessage) {
                return null;
            }
            if (fallbackComponent != null && error != null) {
                const ComponentToRender = fallbackComponent;
                return _jsx(ComponentToRender, { error: error });
            }
            return (_jsx("div", Object.assign({ className: "container" }, { children: _jsx(Message, Object.assign({ type: "error" }, { children: "Der skete en uventet fejl. Pr\u00F8v igen, eller kontakt vores support p\u00E5 88 62 60 50" })) })));
        }
        return children;
    }
}
export default ErrorBoundary;
