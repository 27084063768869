import { createSlice } from '@reduxjs/toolkit';
const initialState = { authenticationModalData: null };
const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setAuthenticationModalData(state, action) {
            state.authenticationModalData = action.payload;
        }
    }
});
export const { setAuthenticationModalData } = modalSlice.actions;
export default modalSlice.reducer;
