var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import { useContext } from '../context';
import styles from '../Select.module.scss';
const StyledOption = (_a) => {
    var { value, index, className, extraClassName, children } = _a, props = __rest(_a, ["value", "index", "className", "extraClassName", "children"]);
    const { selectedValue, highlightedItemIndex, setSelectedValue, setHighlightedItemIndex, isMouseHighlightDisabled, setMouseHighlightDisabled } = useContext();
    const currentIndex = index !== null && index !== void 0 ? index : 0;
    const { 'data-cy': dataCy } = props, rest = __rest(props, ['data-cy']);
    const isMultiSelect = Array.isArray(selectedValue);
    const isOptionSelected = isMultiSelect ? selectedValue.includes(value) : selectedValue === value;
    const handleMouseDown = () => {
        if (isMultiSelect) {
            if (!selectedValue.includes(value)) {
                setSelectedValue([...selectedValue, value]);
            }
            else {
                setSelectedValue(selectedValue.filter((item) => item !== value));
            }
        }
        else if (value !== selectedValue) {
            setSelectedValue(value);
        }
    };
    const handleMouseEnter = () => {
        if (highlightedItemIndex === currentIndex || isMouseHighlightDisabled) {
            return;
        }
        setHighlightedItemIndex(currentIndex);
    };
    const handleMouseMove = () => {
        if (isMouseHighlightDisabled) {
            setMouseHighlightDisabled(false);
        }
    };
    return (_jsx("div", Object.assign({ role: "presentation" }, rest, { onMouseDown: handleMouseDown, onMouseEnter: handleMouseEnter, onMouseMove: handleMouseMove, className: classnames(styles.option, className, extraClassName, {
            [styles.selected]: isOptionSelected,
            [styles.highlighted]: currentIndex === highlightedItemIndex,
            [styles.enableUserSelect]: isOptionSelected && isMultiSelect
        }), "data-cy": dataCy != null ? dataCy : `select-option-${value}` }, { children: children })));
};
export default memo(StyledOption);
