var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { useContext } from '../context';
import { extractTextFromReact } from '../../../utils/extractTextFromReact';
import { DeviceDetection } from '../../../utils/deviceDetection';
import { useThrottle } from '../../../hooks/useThrottle';
import InputWrapper from '../../../helperComponents/InputWrapper';
import Dropdown from './Dropdown';
import IconButton from '../../IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { SelectConstants } from '../Select.constants';
import styles from '../Select.module.scss';
const isMacintosh = DeviceDetection.isMacintosh();
const StyledSelect = (_a) => {
    var _b, _c;
    var { label, name, disabled, placeholder, selectExtraProps, isSearchable = false, emptySearchMessage, maxHeight, dropdownWidth, children, classNames, selectedText, isMultiSelect = false, kind, inputSize, onOpen, onClose, isLoading = false } = _a, restProps = __rest(_a, ["label", "name", "disabled", "placeholder", "selectExtraProps", "isSearchable", "emptySearchMessage", "maxHeight", "dropdownWidth", "children", "classNames", "selectedText", "isMultiSelect", "kind", "inputSize", "onOpen", "onClose", "isLoading"]);
    const [isFocused, setFocused] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isValueSelectedWithEnterKey, setValueSelectedWithEnterKey] = useState(false);
    const [optionsToRender, setOptionsToRender] = useState(React.Children.toArray(children));
    const { selectedValue, setSelectedValue, highlightedItemIndex, setHighlightedItemIndex, isMouseHighlightDisabled, setMouseHighlightDisabled } = useContext();
    const inputWrapperRef = useRef(null);
    const inputRef = useRef(null);
    const areaLabelText = (_b = restProps['aria-label']) !== null && _b !== void 0 ? _b : extractTextFromReact(label !== null && label !== void 0 ? label : placeholder);
    const dropdownIcon = isOpen ? faChevronUp : faChevronDown;
    const selectedOptionNode = getSelectedOptionNode(optionsToRender);
    const inputWrapperValue = selectedValue !== placeholder && searchValue.length === 0 ? selectedValue : '';
    const selectedOptionText = selectedText !== null && selectedText !== void 0 ? selectedText : extractTextFromReact((_c = selectedOptionNode === null || selectedOptionNode === void 0 ? void 0 : selectedOptionNode.props) === null || _c === void 0 ? void 0 : _c.children);
    const throttledSearchValue = isSearchable ? useThrottle(searchValue, SelectConstants.searchKeydownTimeoutMs) : '';
    const isPlaceholderVisible = !(isMultiSelect && selectedOptionNode.length > 0);
    const handleClickDeleteMultiOption = (event, value) => {
        event.stopPropagation();
        if (Array.isArray(selectedValue)) {
            setSelectedValue(selectedValue.filter((item) => item !== value));
            setOpen(false);
        }
    };
    useEffect(() => {
        setOptionsToRender(getOptionNodesToRender());
    }, [isLoading, throttledSearchValue]);
    const dropdownElement = isOpen ? (_jsx(Dropdown, Object.assign({ inputWrapperRef: inputWrapperRef, emptySearchMessage: emptySearchMessage, maxHeight: maxHeight, dropdownWidth: dropdownWidth, name: name, isLoading: isLoading }, { children: optionsToRender }))) : null;
    return (_jsxs("div", Object.assign({ role: "presentation", ref: inputWrapperRef, onClick: handleClickInputWrapper, className: cx(classNames === null || classNames === void 0 ? void 0 : classNames.wrapper) }, { children: [_jsxs(InputWrapper, Object.assign({}, restProps, { name: name, value: inputWrapperValue, isFocused: isFocused, label: label, rightIcon: _jsx(FontAwesomeIcon, { icon: dropdownIcon }), disabled: disabled, kind: kind, inputSize: inputSize }, (isPlaceholderVisible ? { placeholder } : {}), { children: [_jsx("input", Object.assign({}, selectExtraProps, { "aria-label": areaLabelText, name: name, disabled: disabled, ref: inputRef, value: isFocused && isSearchable && !isValueSelectedWithEnterKey ? searchValue : selectedOptionText, onChange: handleChangeSearchValue, onFocus: () => setFocused(true), onBlur: handleBlurInput, onKeyDown: handleKeyDownDropdown, className: cx(classNames === null || classNames === void 0 ? void 0 : classNames.input, styles.styledSelect, {
                            [styles.notTypeable]: !isSearchable,
                            [styles.disabled]: disabled,
                            [styles.isMultiSelect]: isMultiSelect
                        }), autoComplete: "off" }, (isPlaceholderVisible ? { placeholder } : {}))), isMultiSelect && selectedOptionNode.length > 0 ? (_jsx("div", Object.assign({ className: cx(styles.multiOptionsWrapper, styles[kind], styles[inputSize]), "data-cy": "multi-options-list" }, { children: selectedOptionNode.map(({ props }) => (_jsxs("p", Object.assign({ className: styles.multiOption, "data-cy": "multi-options-item" }, { children: [_jsx("span", { children: extractTextFromReact(props.children) }), _jsx(IconButton, { title: "Remove", onClick: (event) => handleClickDeleteMultiOption(event, props.value), icon: faXmark, className: styles.deleteButton, "data-cy": "remove-multi-options-item" })] }), props.value))) }))) : null] })), dropdownElement] })));
    function closeDropdown() {
        closeSelect();
        if (highlightedItemIndex > 0) {
            setHighlightedItemIndex(-1);
        }
        if (isMouseHighlightDisabled) {
            setMouseHighlightDisabled(false);
        }
    }
    function handleChangeSearchValue(event) {
        if (!isSearchable || disabled || isMultiSelect)
            return;
        if (isValueSelectedWithEnterKey) {
            setValueSelectedWithEnterKey(false);
        }
        setSearchValue(event.target.value);
    }
    function handleClickInputWrapper() {
        if (disabled)
            return;
        if (!isOpen) {
            openSelect();
        }
        else {
            closeDropdown();
            if (onClose != null)
                onClose();
        }
    }
    function handleBlurInput() {
        setFocused(false);
        if (searchValue.length > 0) {
            setSearchValue('');
        }
        closeDropdown();
    }
    function getOptionNodesToRender() {
        const options = React.Children.toArray(children);
        return options.filter((option) => {
            var _a;
            if (!isSearchable) {
                return option;
            }
            const optionText = React.isValidElement(option) ? extractTextFromReact((_a = option === null || option === void 0 ? void 0 : option.props) === null || _a === void 0 ? void 0 : _a.children).toLowerCase() : '';
            const trimmedSearchValue = searchValue.trim().toLowerCase();
            if (optionText.includes(trimmedSearchValue)) {
                return option;
            }
            return null;
        }).map((option, key) => {
            var _a;
            if (React.isValidElement(option)) {
                return React.cloneElement(option, Object.assign(Object.assign({}, option.props), { index: key, className: `${name}-option-${option.props.value}`, extraClassName: (_a = option.props.className) !== null && _a !== void 0 ? _a : '' }), option.props.children);
            }
            return option;
        });
    }
    function handleKeyDownDropdown(event) {
        var _a;
        const { key } = event;
        if (isOpen && key === 'Escape') {
            closeSelect();
        }
        else if (isOpen && key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            const highlightedOption = optionsToRender[highlightedItemIndex];
            if (React.isValidElement(highlightedOption)) {
                if (!Array.isArray(selectedValue)) {
                    setSelectedValue(highlightedOption.props.value);
                }
                else if (selectedValue.includes(highlightedOption.props.value)) {
                    setSelectedValue(selectedValue.filter((value) => value !== highlightedOption.props.value));
                }
                else {
                    setSelectedValue([...selectedValue, highlightedOption.props.value]);
                }
                closeSelect();
                setValueSelectedWithEnterKey(true);
            }
        }
        else if (isOpen && ['ArrowUp', 'ArrowDown'].includes(key)) {
            if (!isMouseHighlightDisabled) {
                setMouseHighlightDisabled(true);
            }
            if (key === 'ArrowUp') {
                setHighlightedItemIndex(highlightedItemIndex > 0 ? highlightedItemIndex - 1 : 0);
            }
            else {
                setHighlightedItemIndex(highlightedItemIndex < optionsToRender.length - 1 ? highlightedItemIndex + 1 : optionsToRender.length - 1);
            }
        }
        else if (!isOpen && key === ' ' && !isSearchable) { // Space key
            openSelect();
        }
        if (isMacintosh) {
            if (!isOpen && ['ArrowUp', 'ArrowDown'].includes(key)) {
                openSelect();
            }
        }
        else if (!isOpen && ['ArrowUp', 'ArrowDown'].includes(key)) {
            let selectedOptionIndex = optionsToRender.findIndex((optionChildren) => {
                var _a;
                if (React.isValidElement(optionChildren)) {
                    return ((_a = optionChildren === null || optionChildren === void 0 ? void 0 : optionChildren.props) === null || _a === void 0 ? void 0 : _a.value) === selectedValue;
                }
                return null;
            });
            if (key === 'ArrowDown') {
                if (selectedOptionIndex + 1 !== optionsToRender.length) {
                    selectedOptionIndex += 1;
                }
            }
            else if (key === 'ArrowUp') {
                if (selectedOptionIndex - 1 >= 0) {
                    selectedOptionIndex -= 1;
                }
            }
            const optionToSelect = optionsToRender[selectedOptionIndex];
            if (React.isValidElement(optionToSelect)) {
                setSelectedValue((_a = optionToSelect === null || optionToSelect === void 0 ? void 0 : optionToSelect.props) === null || _a === void 0 ? void 0 : _a.value);
            }
        }
    }
    function getSelectedOptionNode(options) {
        if (isMultiSelect) {
            return options.filter((option) => selectedValue.includes(option.props.value));
        }
        return options.find((option) => option.props.value === selectedValue);
    }
    function openSelect() {
        setOpen(true);
        if (onOpen != null)
            onOpen();
    }
    function closeSelect() {
        setOpen(false);
        if (onClose != null)
            onClose();
    }
};
export default StyledSelect;
