import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../../utils/formatPrice';
import Button from '../../../../components/Button';
import styles from './SidebarSummary.module.scss';
const SidebarSummary = ({ amount, discount, applicationUrl, onGoToBasketClick }) => {
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();
    const handleClickGoToBasket = useCallback(() => {
        setLoading(true);
        onGoToBasketClick();
    }, [applicationUrl]);
    if (amount === 0) {
        return (_jsx("div", Object.assign({ className: classnames(styles.wrapper, styles.empty) }, { children: _jsx("p", { children: t('sidebar.summary.empty') }) })));
    }
    const discountElement = discount > 0 ? (_jsxs("div", Object.assign({ className: styles.price }, { children: [_jsx("p", { children: t('sidebar.summary.discount') }), _jsx("p", { children: formatPrice(discount) })] }))) : null;
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsxs("div", Object.assign({ className: styles.price }, { children: [_jsx("p", { children: t('sidebar.summary.subTotal') }), _jsx("p", { children: formatPrice(amount + discount) })] })), discountElement, _jsxs("div", Object.assign({ className: styles.total }, { children: [_jsx("p", { children: t('sidebar.summary.total') }), _jsx("p", Object.assign({ "data-testid": "purchase-sidebar-total" }, { children: formatPrice(amount) }))] })), _jsx(Button, Object.assign({ size: "fluid", kind: "pink", onClick: handleClickGoToBasket, isLoading: isLoading, "data-testid": "purchase-sidebar-pay-button" }, { children: t('sidebar.summary.goToPayment') }))] })));
};
export default SidebarSummary;
