import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef, useContext } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { getDropdownCoordinates } from '../../../Header.utils';
import { useClickOutside } from '../../../../../hooks/useClickOutside';
import CategoryButton from '../../HeaderElements/MenuButton';
import { getMenuLinks } from '../../../Header.constants';
import styles from './Menu.module.scss';
import { HeaderContext } from '../../../Header.context';
const Menu = () => {
    const { t } = useTranslation();
    const buttonWrapperRef = useRef(null);
    const listRef = useRef(null);
    const { redirectTo, urls, openedMenuItem, setOpenedMenuItem } = useContext(HeaderContext);
    const [dropdownStyle, setDropdownStyle] = useState({ width: 0, left: 0, top: 0 });
    useClickOutside(listRef, () => setOpenedMenuItem(null), buttonWrapperRef);
    const handleMenuButton = () => {
        if (openedMenuItem === 'menu') {
            setOpenedMenuItem(null);
        }
        else {
            setOpenedMenuItem('menu');
        }
    };
    const handleClickDropDownItem = (url) => {
        setOpenedMenuItem(null);
        redirectTo(url);
    };
    useEffect(() => {
        setDropdownStyle(Object.assign(Object.assign({}, getDropdownCoordinates(buttonWrapperRef)), { width: 'auto' }));
    }, [openedMenuItem]);
    const menuListElement = openedMenuItem === 'menu' ? (_jsx("ul", Object.assign({ ref: listRef, className: styles.menu, style: dropdownStyle, "data-cy": "logged-in-menu-list", onMouseLeave: () => setOpenedMenuItem(null) }, { children: getMenuLinks(urls).map((link) => (_jsx("li", Object.assign({ className: styles.menuItem }, { children: _jsx("button", Object.assign({ type: "button", className: styles.menuLink, onClick: () => handleClickDropDownItem(link.url) }, { children: t(`header.authMenu.${link.name}`) })) }), link.name))) }))) : null;
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ ref: buttonWrapperRef }, { children: _jsx(CategoryButton, Object.assign({ onClick: handleMenuButton, onMouseEnter: handleMenuButton, isOpen: openedMenuItem === 'menu', "data-cy": "authMenu-title", hasChildItems: true }, { children: t('header.authMenu.title') })) })), createPortal(menuListElement, document.body)] }));
};
export default Menu;
