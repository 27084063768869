import { applicationSpaRoutes } from '../../constants';
export const getMenuLinks = (urls) => {
    var _a, _b, _c, _d;
    return [
        {
            name: 'myOverview',
            url: `${(_a = urls === null || urls === void 0 ? void 0 : urls.applicationSpa) !== null && _a !== void 0 ? _a : ''}${applicationSpaRoutes.myOverview}`
        },
        {
            name: 'myDocuments',
            url: `${(_b = urls === null || urls === void 0 ? void 0 : urls.applicationSpa) !== null && _b !== void 0 ? _b : ''}${applicationSpaRoutes.myDocuments}`
        },
        {
            name: 'createDocument',
            url: `${(_c = urls === null || urls === void 0 ? void 0 : urls.applicationSpa) !== null && _c !== void 0 ? _c : ''}${applicationSpaRoutes.createDocument}`
        },
        {
            name: 'help',
            url: `${(_d = urls === null || urls === void 0 ? void 0 : urls.frontend) !== null && _d !== void 0 ? _d : ''}/testaviva-support`
        }
    ];
};
