var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import styles from './MenuButton.module.scss';
const MenuButton = forwardRef((_a, ref) => {
    var { onClick, title, onMouseEnter, isOpen = false, children, hasChildItems, url = null } = _a, props = __rest(_a, ["onClick", "title", "onMouseEnter", "isOpen", "children", "hasChildItems", "url"]);
    if (url != null) {
        return (_jsx("a", Object.assign({ href: url, className: cx(styles.button, { [styles.open]: isOpen }) }, props, { children: _jsx("span", Object.assign({ className: styles.buttonContent }, { children: children })) })));
    }
    return (_jsx("button", Object.assign({}, props, { title: title, ref: ref, type: "button", className: cx(styles.button, { [styles.open]: isOpen }), onClick: onClick, "aria-expanded": isOpen, onMouseEnter: onMouseEnter }, { children: hasChildItems ? (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: styles.buttonContent }, { children: children })), _jsx(FontAwesomeIcon, { className: styles.buttonIcon, icon: faChevronDown })] })) : (_jsx("span", Object.assign({ className: styles.buttonContent }, { children: children }))) })));
});
export default MenuButton;
