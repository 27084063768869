import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import IconButton from '../../../../components/IconButton';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import styles from './SidebarHeader.module.scss';
const SidebarHeader = ({ onClose }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx("h3", Object.assign({ className: styles.title }, { children: t('sidebar.title') })), _jsx("div", Object.assign({ role: "presentation", className: styles.arrowWrapper, onClick: onClose }, { children: _jsx(IconButton, { icon: faAngleRight, title: "Close", iconClassNames: styles.arrow, className: styles.closeButton, "data-testid": "purchase-sidebar-close-button" }) }))] })));
};
export default SidebarHeader;
