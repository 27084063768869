import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthToken } from '@frontend-components/utils/auth';
import { applicationUrl, paymentApiUrl } from '@constants';
import { authHeaderName } from '@frontend-components/constants';
export const PRODUCTS_API_REDUCER_KEY = 'productsApi';
export const productsApi = createApi({
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            const authToken = getAuthToken();
            if (authToken != null && authToken && authToken.length > 0) {
                headers.set(authHeaderName, authToken);
            }
            return headers;
        }
    }),
    reducerPath: PRODUCTS_API_REDUCER_KEY,
    endpoints: (builder) => ({
        getAllProductsInfo: builder.query({
            query: () => ({
                url: `${applicationUrl}/api/get-all-products`,
                method: 'GET'
            })
        }),
        getProductPrices: builder.query({
            query: (partner) => ({
                url: `${paymentApiUrl}/api/price-set${partner != null ? `?partner=${partner}` : ''}`,
                method: 'GET'
            })
        }),
        getUserRecommendedProducts: builder.query({
            query: () => ({
                url: `${applicationUrl}/api/score/recommended-products`,
                method: 'GET'
            })
        })
    })
});
export const { useGetAllProductsInfoQuery, useGetProductPricesQuery, useGetUserRecommendedProductsQuery } = productsApi;
