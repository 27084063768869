import { Cookie } from '../cookie';
import { authCookieName, authHeaderName } from '../../constants';
export function getAuthToken() {
    let token = '';
    const cookie = Cookie.get(authCookieName);
    if (cookie != null) {
        token = JSON.parse(cookie).token;
    }
    return token;
}
export function getRequestAuthHeader() {
    return { [authHeaderName]: getAuthToken() };
}
