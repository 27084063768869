export function getPriceSetProductPrice({ priceSet, purchaseType, product }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const prices = {
        current: 0,
        origin: 0
    };
    if (priceSet == null || purchaseType == null || product == null) {
        return prices;
    }
    if ((priceSet === null || priceSet === void 0 ? void 0 : priceSet.partner) != null) {
        if (((_a = priceSet === null || priceSet === void 0 ? void 0 : priceSet[purchaseType]) === null || _a === void 0 ? void 0 : _a[product]) != null) {
            prices.current = (_c = (_b = priceSet === null || priceSet === void 0 ? void 0 : priceSet[purchaseType]) === null || _b === void 0 ? void 0 : _b[product]) !== null && _c !== void 0 ? _c : 0;
        }
        else if (((_d = priceSet === null || priceSet === void 0 ? void 0 : priceSet[purchaseType]) === null || _d === void 0 ? void 0 : _d.default) != null) {
            prices.current = (_f = (_e = priceSet === null || priceSet === void 0 ? void 0 : priceSet[purchaseType]) === null || _e === void 0 ? void 0 : _e.default) !== null && _f !== void 0 ? _f : 0;
        }
        else {
            const priceSetProduct = priceSet === null || priceSet === void 0 ? void 0 : priceSet.products.find((productItem) => productItem.type === product);
            if (((_g = priceSetProduct === null || priceSetProduct === void 0 ? void 0 : priceSetProduct.prices) === null || _g === void 0 ? void 0 : _g[purchaseType]) != null) {
                prices.current = Number((_h = priceSetProduct === null || priceSetProduct === void 0 ? void 0 : priceSetProduct.prices) === null || _h === void 0 ? void 0 : _h[purchaseType]);
            }
            else {
                prices.current = (_k = (_j = priceSet === null || priceSet === void 0 ? void 0 : priceSet[purchaseType]) === null || _j === void 0 ? void 0 : _j.non_partner_default) !== null && _k !== void 0 ? _k : 0;
            }
        }
        const priceSetProduct = priceSet === null || priceSet === void 0 ? void 0 : priceSet.products.find((productItem) => productItem.type === product);
        if (((_l = priceSetProduct === null || priceSetProduct === void 0 ? void 0 : priceSetProduct.prices) === null || _l === void 0 ? void 0 : _l[purchaseType]) != null) {
            prices.origin = Number((_o = (_m = priceSetProduct === null || priceSetProduct === void 0 ? void 0 : priceSetProduct.prices) === null || _m === void 0 ? void 0 : _m[purchaseType]) !== null && _o !== void 0 ? _o : 0);
        }
        else {
            prices.origin = Number((_q = (_p = priceSet === null || priceSet === void 0 ? void 0 : priceSet[purchaseType]) === null || _p === void 0 ? void 0 : _p.non_partner_default) !== null && _q !== void 0 ? _q : 0);
        }
    }
    else {
        const priceSetProduct = priceSet === null || priceSet === void 0 ? void 0 : priceSet.products.find((productItem) => productItem.type === product);
        if (((_r = priceSetProduct === null || priceSetProduct === void 0 ? void 0 : priceSetProduct.prices) === null || _r === void 0 ? void 0 : _r[purchaseType]) != null) {
            prices.current = Number((_s = priceSetProduct === null || priceSetProduct === void 0 ? void 0 : priceSetProduct.prices) === null || _s === void 0 ? void 0 : _s[purchaseType]);
        }
        else {
            prices.current = (_u = (_t = priceSet === null || priceSet === void 0 ? void 0 : priceSet[purchaseType]) === null || _t === void 0 ? void 0 : _t.default) !== null && _u !== void 0 ? _u : 0;
        }
        prices.origin = prices.current;
    }
    return prices;
}
