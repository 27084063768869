import { PurchaseTypes, digitalProducts } from '../../constants';
export function isProductDigital(name) {
    return digitalProducts.includes(name);
}
export function getProductEligiblePurchaseTypes(item) {
    var _a;
    // If item purchase type is not upgrade
    if (![PurchaseTypes.upgrade_to_review, PurchaseTypes.upgrade_to_advice].includes(item.purchase_type)) {
        if (!isProductDigital(item.product)) {
            return [PurchaseTypes.digital_document_advice];
        }
        return [PurchaseTypes.digital_document_basis, PurchaseTypes.digital_document_review, PurchaseTypes.digital_document_advice];
    }
    // eslint-disable-next-line no-useless-concat
    const upgradeFromPurchaseType = (_a = item.identifier) === null || _a === void 0 ? void 0 : _a.replace(new RegExp('.*' + 'upgrade_from:'), '');
    if (upgradeFromPurchaseType === PurchaseTypes.digital_document_basis) {
        return [PurchaseTypes.upgrade_to_review, PurchaseTypes.upgrade_to_advice];
    }
    return [PurchaseTypes.upgrade_to_advice];
}
