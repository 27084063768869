import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchPartners } from '../../hooks/useFetchPartners';
import Button from '../../../../components/Button';
import RadioButton from '../../../../components/RadioButton';
import Spinner from '../../../../components/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import styles from './PartnerSelect.module.scss';
import sharedStyles from '../../styles.module.scss';
import { AuthenticationSteps, nonePartnerId } from '../../constants';
const ParnerSelect = ({ onSubmit, onFetchPartners, onSwitchStep }) => {
    const { t } = useTranslation();
    const [selectedPartner, setSelectedPartner] = useState(null);
    const { data: partnersData, isLoading: isPartnersDataLoading } = useFetchPartners(onFetchPartners);
    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedPartner == null)
            return;
        onSubmit(selectedPartner);
    };
    const spinnerElement = isPartnersDataLoading ? _jsx(Spinner, { position: "center" }) : null;
    const partnersElement = !isPartnersDataLoading ? partnersData != null && partnersData.map((partner) => (_jsxs("div", Object.assign({ role: "presentation", onClick: () => setSelectedPartner(partner.id), "data-cy": `partner-select-radio-${partner.id}`, className: styles.partnersListItem }, { children: [_jsx("div", Object.assign({ className: styles.checkboxWrapper }, { children: _jsx(RadioButton, { label: "", checked: selectedPartner === partner.id, handleOnChange: setSelectedPartner, name: "partner-name" }) })), _jsx("div", Object.assign({ className: styles.content }, { children: _jsx("img", { src: partner.logoFullPath, alt: partner.id }) }))] }), partner.id))) : null;
    return (_jsxs("form", Object.assign({ className: sharedStyles.form, onSubmit: handleSubmit, "data-cy": "partner-select-step" }, { children: [_jsx("p", Object.assign({ className: sharedStyles.title }, { children: t('authentication.partnerSelect.title') })), _jsx("p", Object.assign({ className: sharedStyles.title }, { children: t('authentication.partnerSelect.subTitle') })), _jsxs("div", Object.assign({ className: styles.contentWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.partnersList }, { children: [_jsxs("div", Object.assign({ role: "presentation", onClick: () => setSelectedPartner(nonePartnerId), "data-cy": `partner-select-radio-${nonePartnerId}`, className: styles.partnersListItem }, { children: [_jsx("div", Object.assign({ className: styles.checkboxWrapper }, { children: _jsx(RadioButton, { label: "", checked: selectedPartner === nonePartnerId, handleOnChange: setSelectedPartner, name: "partner-name" }) })), _jsx("div", Object.assign({ className: styles.content }, { children: _jsx("p", { children: t('authentication.partnerSelect.noPartner') }) }))] })), partnersElement] })), spinnerElement] })), _jsxs("div", Object.assign({ className: sharedStyles.formNavigationButtons }, { children: [_jsx(Button, Object.assign({ onClick: () => onSwitchStep(AuthenticationSteps.USER_INFO), kind: "text", size: "fluid", icon: _jsx(FontAwesomeIcon, { icon: faArrowLeft, className: sharedStyles.buttonIcon }), "data-cy": "partner-select-step-back-button" }, { children: t('authentication.backCta') })), _jsx(Button, Object.assign({ type: "submit", color: "pink", size: "fluid", "data-cy": "partner-select-step-submit-button", disabled: selectedPartner == null }, { children: t('authentication.partnerSelect.submit') }))] }))] })));
};
export default ParnerSelect;
