var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import IconButton from '../IconButton';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import styles from './Modal.module.scss';
const Modal = (_a) => {
    var { onClose, classNames, children, preventOverlayClose = false, withCustomContent = false } = _a, props = __rest(_a, ["onClose", "classNames", "children", "preventOverlayClose", "withCustomContent"]);
    const isClosable = onClose != null && typeof onClose === 'function';
    const handleWindowKeydown = (event) => {
        if (onClose != null && typeof onClose === 'function' && event.code === 'Escape') {
            onClose();
        }
    };
    useEffect(() => {
        document.body.setAttribute('style', 'overflow:hidden;');
        window.addEventListener('keydown', handleWindowKeydown);
        return () => {
            document.body.removeAttribute('style');
            window.removeEventListener('keydown', handleWindowKeydown);
        };
    }, []);
    return createPortal(_jsxs("div", Object.assign({}, props, { className: styles.wrapper }, { children: [_jsx("div", Object.assign({ "data-testid": "modal-overlay", className: styles.overlay }, (isClosable && !preventOverlayClose ? { onClick: onClose } : {}))), _jsx("div", Object.assign({ className: classnames(styles.modal, classNames === null || classNames === void 0 ? void 0 : classNames.modal) }, { children: !withCustomContent ? (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: classnames(styles.modalHeader, classNames === null || classNames === void 0 ? void 0 : classNames.modalHeader) }, { children: _jsx(IconButton, { title: "Close Button", onClick: onClose, icon: faTimes, "data-testid": "modal-close-button", className: classnames(styles.closeButton, classNames === null || classNames === void 0 ? void 0 : classNames.closeButton, { [styles.disabled]: !isClosable }) }) })), _jsx("div", Object.assign({ className: classnames(styles.modalContent, classNames === null || classNames === void 0 ? void 0 : classNames.modalContent) }, { children: children }))] })) : children }))] })), document.body);
};
export default Modal;
