var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import classnames from 'classnames';
import { extractTextFromReact } from '../../utils/extractTextFromReact';
import Spinner from '../Spinner';
import { ButtonKinds, ButtonSizes } from './Button.types';
import styles from './Button.module.scss';
const defaultKind = ButtonKinds.primary;
const defaultSize = ButtonSizes.medium;
const Button = (_a) => {
    var _b;
    var { disabled = false, className = '', kind = defaultKind, type = 'button', size = defaultSize, icon = null, onClick = null, isLoading = false, children, color = 'teal', href = null } = _a, props = __rest(_a, ["disabled", "className", "kind", "type", "size", "icon", "onClick", "isLoading", "children", "color", "href"]);
    const buttonRef = useRef(null);
    const areaLabelText = (_b = props['aria-label']) !== null && _b !== void 0 ? _b : extractTextFromReact(children);
    return href == null ? (_jsxs("button", Object.assign({}, props, { ref: buttonRef, 
        // eslint-disable-next-line react/button-has-type
        type: type, className: classnames(styles.button, styles[kind], styles[size], styles[color], className, { [styles.disabled]: disabled, [styles.loading]: isLoading }), disabled: disabled, onClick: handleClick, "aria-label": areaLabelText }, { children: [isLoading ? _jsx(Spinner, { size: "small", className: styles.buttonSpinner }) : null, _jsxs("span", Object.assign({ style: { opacity: isLoading ? 0 : 1 } }, { children: [icon != null ? _jsx("span", Object.assign({ className: styles.icon }, { children: icon })) : null, ' ', children] }))] }))) : (_jsxs("a", Object.assign({}, ((props === null || props === void 0 ? void 0 : props['data-cy']) != null ? { 'data-cy': props['data-cy'] } : null), { href: href, type: type, className: classnames(styles.link, styles[kind], styles[size], styles[color], className, { [styles.disabled]: disabled, [styles.loading]: isLoading }), "aria-label": areaLabelText }, { children: [isLoading ? _jsx(Spinner, { size: "small", className: styles.buttonSpinner }) : null, _jsxs("span", Object.assign({ style: { opacity: isLoading ? 0 : 1 } }, { children: [icon != null ? _jsx("span", Object.assign({ className: styles.icon }, { children: icon })) : null, ' ', children] }))] })));
    function handleClick(event) {
        var _a;
        if (disabled || isLoading) {
            return;
        }
        (_a = buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        if (typeof onClick === 'function') {
            onClick(event);
        }
    }
};
export default Button;
