export function getStorageItem(key) {
    const data = localStorage.getItem(key);
    return data != null ? JSON.parse(data) : null;
}
export function setStorageItem(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}
export function removeStorageItem(key) {
    localStorage.removeItem(key);
}
