import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { paymentApiUrl } from '@constants';
import { authHeaderName } from '@frontend-components/constants';
import { getAuthToken } from '@frontend-components/utils/auth';
export const PAYMENT_API_REDUCER_KEY = 'paymentApi';
export const paymentApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: paymentApiUrl,
        prepareHeaders: (headers) => {
            const authToken = getAuthToken();
            if (authToken != null && authToken && authToken.length > 0) {
                headers.set(authHeaderName, authToken);
            }
            return headers;
        }
    }),
    reducerPath: PAYMENT_API_REDUCER_KEY,
    endpoints: (builder) => ({
        getProductPrices: builder.query({
            query: (partner) => ({
                url: partner != null && partner !== 'default' ? `/api/price-set?partner=${partner}` : '/api/price-set',
                method: 'GET'
            })
        })
    })
});
export const { useGetProductPricesQuery, useLazyGetProductPricesQuery } = paymentApi;
