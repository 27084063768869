var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import AuthenticationModal from '@frontend-components/features/Authentication/components/AuthenticationModal';
import { useLazyGetPartnersQuery } from '@store/api/partner';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { usePostBasketItemMutation } from '@store/api/basket';
import { useGetUserDetailsQuery } from '@store/api/user';
import { setAuthenticationModalData } from '@store/slices/modalsSlice';
import { applicationUrl, applicationSpaUrl } from '@constants';
const AuthenticationBooking = () => {
    const dispatch = useDispatch();
    const { authenticationModalData } = useSelector((state) => ({ authenticationModalData: state.modal.authenticationModalData }), shallowEqual);
    const { data: userDetailsData } = useGetUserDetailsQuery();
    const [fetchPartners] = useLazyGetPartnersQuery();
    const [postBasket] = usePostBasketItemMutation();
    useEffect(() => {
        if (authenticationModalData != null && authenticationModalData.isAuthenticated) {
            addToBasketAndRedirect();
        }
    }, [authenticationModalData]);
    return authenticationModalData != null && !authenticationModalData.isAuthenticated ? (_jsx(AuthenticationModal, Object.assign({ onClose: () => dispatch(setAuthenticationModalData(null)), step: authenticationModalData.step, apiUrl: applicationUrl, onFetchPartners: () => fetchPartners().unwrap() }, (authenticationModalData.partner != null ? { preselectedPartner: authenticationModalData.partner } : {}), (authenticationModalData != null && (authenticationModalData === null || authenticationModalData === void 0 ? void 0 : authenticationModalData.productType) != null && (authenticationModalData === null || authenticationModalData === void 0 ? void 0 : authenticationModalData.purchaseType) != null ? { onSubmitCallback: addToBasketAndRedirect } : {})))) : null;
    function addToBasketAndRedirect() {
        return __awaiter(this, void 0, void 0, function* () {
            if (authenticationModalData == null || (authenticationModalData === null || authenticationModalData === void 0 ? void 0 : authenticationModalData.productType) == null || (authenticationModalData === null || authenticationModalData === void 0 ? void 0 : authenticationModalData.purchaseType) == null)
                return;
            const { purchaseType, productType } = authenticationModalData;
            yield postBasket((userDetailsData === null || userDetailsData === void 0 ? void 0 : userDetailsData.onboardingPartner) != null ? {
                product: productType,
                purchase_type: purchaseType,
                partner: userDetailsData.onboardingPartner.id
            } : {
                product: productType,
                purchase_type: purchaseType
            }).unwrap();
            if (authenticationModalData.purchaseType === 'digital_document_advice') {
                window.location.href = `${applicationSpaUrl}/basket?preselect_booking=true`;
            }
            else {
                window.location.href = `${applicationUrl}/opret-dokument/${authenticationModalData.productType}`;
            }
        });
    }
};
export default AuthenticationBooking;
