export function getPartnerConsentsToRender(selectedPartner, partnersData) {
    var _a, _b, _c, _d;
    let partnerConsentsToRender = [];
    const partnerData = partnersData.find((partner) => partner.id === selectedPartner);
    if (partnerData == null) {
        return partnerConsentsToRender;
    }
    const signupFormConsents = Array.isArray((_a = partnerData === null || partnerData === void 0 ? void 0 : partnerData.consent) === null || _a === void 0 ? void 0 : _a.consents) ? (_b = partnerData === null || partnerData === void 0 ? void 0 : partnerData.consent) === null || _b === void 0 ? void 0 : _b.consents : Object.values((_d = (_c = partnerData === null || partnerData === void 0 ? void 0 : partnerData.consent) === null || _c === void 0 ? void 0 : _c.consents) !== null && _d !== void 0 ? _d : {});
    partnerConsentsToRender = signupFormConsents.filter((consent) => consent.visible.includes('CreateUserForm')).map((consent) => {
        const consentType = consent.options[0].consentType[0];
        return {
            text: consent.text,
            required: consent.required,
            consentType,
            error: consent.error,
            id: consent.id
        };
    });
    return partnerConsentsToRender;
}
