import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useEffect, useRef } from 'react';
import { extendRecommendedProductsResponse, scrollTop } from './PurchaseSidebar.utils';
import Drawer from '../../components/Drawer';
import SidebarHeader from './components/SidebarHeader';
import SidebarBasketItem from './components/SidebarBasketItem';
import SidebarSummary from './components/SidebarSummary';
import SidebarRecommendedProductsWrapper from './components/SidebarRecommendedProductsWrapper';
import SidebarOnboardingPartnerBanner from './components/SidebarOnboardingPartnerBanner';
import Spinner from '../../components/Spinner';
import { BasketChangeEvents } from './PurchaseSidebar.types';
import styles from './PurchaseSidebar.module.scss';
const PurchaseSidebar = ({ user, basket, isLoading, open = null, onOpen, onClose, onGoToBasketClick, onBasketChange, recommendedProductsData = [], priceSet, onboardingPartnerText, serviceUrls = {
    applicationUrl: '',
    paymentUrl: ''
}, drawerExtraProps = {} }) => {
    var _a, _b, _c, _d;
    const drawerRef = useRef(null);
    const [isSidebarOpen, setSidebarOpen] = useState(open !== null && open !== void 0 ? open : false);
    const [recommendedProducts, setRecommendedProducts] = useState(extendRecommendedProductsResponse(recommendedProductsData));
    const basketItems = (_a = basket === null || basket === void 0 ? void 0 : basket.items) !== null && _a !== void 0 ? _a : [];
    const toggleSidebar = useCallback(() => {
        setSidebarOpen((prevState) => !prevState);
        if (!isSidebarOpen && typeof onOpen === 'function') {
            onOpen();
        }
        if (isSidebarOpen && typeof onClose === 'function') {
            onClose();
        }
    }, [isSidebarOpen, onOpen]);
    const handleChangeItemPurchaseType = useCallback((product, purchaseType, id) => {
        if (id == null) {
            const updatedRecommendedProducts = recommendedProducts.map((item) => {
                if (item.product === product) {
                    return { product, purchase_type: purchaseType };
                }
                return item;
            });
            setRecommendedProducts(updatedRecommendedProducts);
        }
        else {
            onBasketChange(BasketChangeEvents.EDIT, { id, purchase_type: purchaseType, product });
        }
    }, [onBasketChange, recommendedProducts]);
    const handleClickItemDelete = useCallback((id) => {
        onBasketChange(BasketChangeEvents.DELETE, { id });
        scrollTop(drawerRef);
    }, [onBasketChange]);
    const handleClickItemAdd = useCallback((product, purchaseType) => {
        const filteredRecommendedProducts = recommendedProducts.filter((item) => item.product !== product);
        setRecommendedProducts(filteredRecommendedProducts);
        onBasketChange(BasketChangeEvents.ADD, { product, purchase_type: purchaseType });
        scrollTop(drawerRef);
    }, [onBasketChange, recommendedProducts]);
    useEffect(() => {
        if (recommendedProductsData.length > 0) {
            setRecommendedProducts(extendRecommendedProductsResponse(recommendedProductsData));
        }
    }, [recommendedProductsData]);
    useEffect(() => {
        if (open != null && open !== isSidebarOpen) {
            setSidebarOpen(open);
        }
    }, [open, isSidebarOpen]);
    const basketItemElements = basketItems.map((item) => (_jsx(SidebarBasketItem, { item: item, onPurchaseTypeChange: handleChangeItemPurchaseType, onDelete: handleClickItemDelete, isLoading: isLoading, identifier: "basket" }, item.id)));
    const basketRecommendedProducts = recommendedProducts.slice(0, 3).map((item) => (_jsx(SidebarBasketItem, { item: item, onAdd: handleClickItemAdd, onPurchaseTypeChange: handleChangeItemPurchaseType, priceSet: priceSet, isLoading: isLoading, identifier: "recommended" }, item.product)));
    const spinnerElement = isLoading ? (_jsx(Spinner, { position: "center", withOverlay: true, className: styles.spinner, "data-testid": "purchase-sidebar-spinner" })) : null;
    const onboardingPartnerBannerElement = onboardingPartnerText != null && typeof onboardingPartnerText === 'string' && onboardingPartnerText.length > 0 ? (_jsx(SidebarOnboardingPartnerBanner, { onboardingPartner: (_b = user === null || user === void 0 ? void 0 : user.onboardingPartner) !== null && _b !== void 0 ? _b : null, partnerText: onboardingPartnerText, applicationUrl: serviceUrls.applicationUrl })) : null;
    return (_jsxs(Drawer, Object.assign({ open: isSidebarOpen, onClose: toggleSidebar, header: _jsx(SidebarHeader, { onClose: toggleSidebar }), classNames: { scrollArea: styles.scrollArea }, ref: drawerRef, drawerExtraProps: drawerExtraProps }, { children: [spinnerElement, onboardingPartnerBannerElement, basketItemElements, _jsx(SidebarSummary, { discount: (_c = basket === null || basket === void 0 ? void 0 : basket.discount) !== null && _c !== void 0 ? _c : 0, amount: (_d = basket === null || basket === void 0 ? void 0 : basket.amount) !== null && _d !== void 0 ? _d : 0, applicationUrl: serviceUrls.applicationUrl, onGoToBasketClick: onGoToBasketClick }), _jsx(SidebarRecommendedProductsWrapper, { children: recommendedProducts.length === 0 ? _jsx(Spinner, { className: "py-8", position: "center" }) : basketRecommendedProducts })] })));
};
export default PurchaseSidebar;
