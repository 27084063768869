var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, lazy, Suspense } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useGetUserDetailsQuery } from '@store/api/user';
import { setPurchaseSidebarOpen } from '@store/slices/sidebarSlice';
import { useGetProductPricesQuery, useGetUserRecommendedProductsQuery } from '@store/api/products';
import { useGetBasketPartnerTextQuery, useGetBasketQuery, usePostBasketItemMutation, usePutBasketItemMutation, useDeleteBasketItemMutation } from '@store/api/basket';
import { applicationUrl, paymentApiUrl, applicationSpaUrl } from '@constants';
import { BasketChangeEvents } from '@frontend-components/features/PurchaseSidebar';
const PurchaseSidebarFeature = lazy(() => import('@frontend-components/features/PurchaseSidebar'));
const PurchaseSidebar = () => {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const { isSidebarOpen } = useSelector((state) => ({ isSidebarOpen: state.sidebar.isOpen }), shallowEqual);
    const { data: userDetails } = useGetUserDetailsQuery();
    const { data: recommendedProducts } = useGetUserRecommendedProductsQuery(null, { skip: !isSidebarOpen });
    const { data: priceSet } = useGetProductPricesQuery((_c = (_b = (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.partners) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null, { skip: !isSidebarOpen });
    const { data: basketData, isFetching: isBasketFetching } = useGetBasketQuery(null, { skip: (userDetails === null || userDetails === void 0 ? void 0 : userDetails.id) == null });
    const { data: basketPartnerText } = useGetBasketPartnerTextQuery(null, { skip: (userDetails === null || userDetails === void 0 ? void 0 : userDetails.onboardingPartner) == null || ((_d = userDetails === null || userDetails === void 0 ? void 0 : userDetails.onboardingPartner) === null || _d === void 0 ? void 0 : _d.name) == null || !isSidebarOpen });
    const [postBasket, { isLoading: isPostBasketLoading }] = usePostBasketItemMutation();
    const [putBasket, { isLoading: isPutBasketLoading }] = usePutBasketItemMutation();
    const [deleteBasket, { isLoading: isDeleteBasketLoading }] = useDeleteBasketItemMutation();
    const handleChangeBasket = (event, item) => __awaiter(void 0, void 0, void 0, function* () {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { product, purchase_type, id } = item;
        if (event === BasketChangeEvents.ADD) {
            if (product != null && purchase_type != null) {
                yield postBasket((userDetails === null || userDetails === void 0 ? void 0 : userDetails.onboardingPartner) != null ? { product, purchase_type, partner: userDetails.onboardingPartner.id } : { product, purchase_type });
            }
        }
        else if (event === BasketChangeEvents.EDIT) {
            if (id != null && purchase_type != null) {
                yield putBasket({ id, purchase_type });
            }
        }
        else if (event === BasketChangeEvents.DELETE) {
            if (id != null) {
                yield deleteBasket(id);
            }
        }
    });
    const filteredRecommendedProducts = useMemo(() => {
        if (recommendedProducts == null || basketData == null) {
            return [];
        }
        let products = [...recommendedProducts];
        basketData.items.forEach((item) => {
            if (recommendedProducts.includes(item.product)) {
                products = products.filter((product) => product !== item.product);
            }
        });
        return products;
    }, [recommendedProducts, basketData]);
    if (userDetails == null || !userDetails.authenticated) {
        return null;
    }
    const isBasketSpinnerVisible = isPostBasketLoading || isPutBasketLoading || isDeleteBasketLoading || isBasketFetching;
    return (_jsx(Suspense, Object.assign({ fallback: "" }, { children: _jsx(PurchaseSidebarFeature, { user: userDetails, basket: basketData, priceSet: priceSet, isLoading: isBasketSpinnerVisible, open: isSidebarOpen, onOpen: () => dispatch(setPurchaseSidebarOpen(true)), onClose: () => dispatch(setPurchaseSidebarOpen(false)), 
            // eslint-disable-next-line no-return-assign
            onGoToBasketClick: () => window.location.href = `${applicationSpaUrl}/basket`, recommendedProductsData: filteredRecommendedProducts, onBasketChange: handleChangeBasket, serviceUrls: { applicationUrl: applicationUrl, paymentUrl: paymentApiUrl }, onboardingPartnerText: basketPartnerText }) })));
};
export default PurchaseSidebar;
