var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import { useScript } from '../../../../hooks/useScript';
import { generateRecaptchaToken } from '../../../../utils/generateRecaptchaToken';
import Login from '../../steps/Login';
import ResetPassword from '../../steps/ResetPassword';
import UserInfo from '../../steps/UserInfo';
import PartnerSelect from '../../steps/PartnerSelect';
import Consents from '../../steps/Consents';
import Validation from '../../steps/Validation';
import EmailVerification from '../../steps/EmailVerification';
import UserSelect from '../../steps/UserSelect';
import { AuthenticationSteps, nonePartnerId, SubmitActions } from '../../constants';
import { recaptchaScriptUrl } from '../../../../constants';
const AuthenticationFlow = ({ step = AuthenticationSteps.EMAIL_VERIFICATION, apiUrl = '', preselectedPartner = null, onSubmitCallback, onFetchPartners }) => {
    var _a, _b, _c;
    const [currentStep, setCurrentStep] = useState(step);
    const [isLoading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [createUserErrors, setCreateUserErrors] = useState({});
    const [selectedPartner, setSelectedPartner] = useState(preselectedPartner);
    const [accountUsers, setAccountUsers] = useState([]);
    const [loginRedirectUrl, setLoginRedirectUrl] = useState('');
    useScript(recaptchaScriptUrl);
    const onSubmitUserInfo = useCallback((data) => {
        setUserData(data);
        if (preselectedPartner == null && onFetchPartners != null) {
            setCurrentStep(AuthenticationSteps.PARTNER_SELECT);
            return;
        }
        setCurrentStep(AuthenticationSteps.CONSENTS);
    }, [preselectedPartner, onFetchPartners]);
    const onSubmitPartnerSelect = useCallback((partner) => {
        if (partner !== nonePartnerId) {
            setSelectedPartner(partner);
        }
        setCurrentStep(AuthenticationSteps.CONSENTS);
    }, [selectedPartner]);
    const onSubmitEmailVerification = useCallback(({ nextStep, email }) => {
        setUserData({ email });
        setCurrentStep(nextStep);
    }, []);
    const onSubmitLoginUserSelect = useCallback(({ users, redirectUrl }) => {
        setAccountUsers(users);
        setLoginRedirectUrl(redirectUrl);
    }, []);
    const setGenericError = useCallback(() => {
        setCreateUserErrors({ generic: true });
        setCurrentStep(AuthenticationSteps.USER_INFO);
    }, []);
    const onCreateUser = (acceptedConsents) => __awaiter(void 0, void 0, void 0, function* () {
        if (userData == null || isLoading)
            return;
        setLoading(true);
        let formData = {};
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { firstName, lastName } = userData, rest = __rest(userData, ["firstName", "lastName"]);
        formData = Object.assign(Object.assign(Object.assign({}, rest), { fullname: `${firstName} ${lastName}` }), acceptedConsents);
        if (acceptedConsents.consents.includes('marketing')) {
            formData = Object.assign(Object.assign({}, formData), { marketing: 1 });
        }
        if (selectedPartner != null) {
            formData = Object.assign(Object.assign({}, formData), { partner: selectedPartner });
        }
        const token = yield generateRecaptchaToken();
        formData = Object.assign(Object.assign({}, formData), { token });
        try {
            yield createUser(formData);
        }
        catch (_d) {
            setGenericError();
            setLoading(false);
        }
    });
    return (_jsxs(_Fragment, { children: [currentStep === AuthenticationSteps.EMAIL_VERIFICATION && (_jsx(EmailVerification, { email: (_a = userData === null || userData === void 0 ? void 0 : userData.email) !== null && _a !== void 0 ? _a : '', apiUrl: apiUrl, onSwitchStep: setCurrentStep, onSubmit: onSubmitEmailVerification })), currentStep === AuthenticationSteps.LOGIN && (_jsx(Login, { onSwitchStep: setCurrentStep, onSetLoading: (isLoginLoading) => setLoading(isLoginLoading), onSubmitCallback: onSubmitCallback, onSubmitUserSelectCallback: onSubmitLoginUserSelect, apiUrl: apiUrl, email: (_b = userData === null || userData === void 0 ? void 0 : userData.email) !== null && _b !== void 0 ? _b : '' })), currentStep === AuthenticationSteps.RESET_PASSWORD && (_jsx(ResetPassword, { onSwitchStep: setCurrentStep, onSetLoading: (isLoginLoading) => setLoading(isLoginLoading), apiUrl: apiUrl, email: (_c = userData === null || userData === void 0 ? void 0 : userData.email) !== null && _c !== void 0 ? _c : '' })), currentStep === AuthenticationSteps.USER_INFO && (_jsx(UserInfo, { data: userData, errors: createUserErrors, onSubmit: onSubmitUserInfo, onSwitchStep: setCurrentStep })), currentStep === AuthenticationSteps.PARTNER_SELECT && onFetchPartners != null && preselectedPartner == null ? (_jsx(PartnerSelect, { onFetchPartners: onFetchPartners, onSubmit: onSubmitPartnerSelect, onSwitchStep: setCurrentStep })) : null, currentStep === AuthenticationSteps.USER_SELECT && (_jsx(UserSelect, { users: accountUsers, apiUrl: apiUrl, redirectUrl: loginRedirectUrl })), currentStep === AuthenticationSteps.CONSENTS && (_jsx(Consents, { onSubmit: onCreateUser, selectedPartner: selectedPartner, onFetchPartners: onFetchPartners, onSwitchStep: setCurrentStep, isLoading: isLoading })), currentStep === AuthenticationSteps.VALIDATION && (_jsx(Validation, { onSwitchStep: setCurrentStep }))] }));
    function createUser(formData) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`${apiUrl}/api/user/create`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
                credentials: 'include'
            });
            if (response.ok) {
                if (formData.password == null) {
                    return response.json();
                }
                if (response.status === 208) {
                    setCurrentStep(AuthenticationSteps.VALIDATION);
                    setLoading(false);
                    return null;
                }
                if (onSubmitCallback != null) {
                    onSubmitCallback(response, SubmitActions.CREATE_USER);
                    return null;
                }
                const responseText = yield response.json();
                window.location.href = responseText;
                return null;
            }
            try {
                const errorResult = yield response.json();
                if ((errorResult === null || errorResult === void 0 ? void 0 : errorResult.element) != null) {
                    setCreateUserErrors({ [errorResult.element]: errorResult.error });
                    setCurrentStep(AuthenticationSteps.USER_INFO);
                }
                else {
                    setGenericError();
                }
            }
            catch (_a) {
                setGenericError();
            }
            setLoading(false);
            return null;
        });
    }
};
export default AuthenticationFlow;
