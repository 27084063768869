import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useEffect, useState, useContext } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../../../../components/Tooltip';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { applicationSpaRoutes } from '../../../../../constants';
import styles from './HeaderNotifications.module.scss';
import { HeaderContext } from '../../../Header.context';
const HeaderNotifications = () => {
    const ref = useRef(null);
    const { t } = useTranslation();
    const { urls, redirectTo, notifications, actions } = useContext(HeaderContext);
    const [fetchedNotifications, setFetchedNotifications] = useState(notifications !== null && notifications !== void 0 ? notifications : []);
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    const applicationUrl = urls === null || urls === void 0 ? void 0 : urls.application;
    const { onNotificationsUpdate } = actions !== null && actions !== void 0 ? actions : {};
    const fetchNotifications = () => {
        fetch(`${applicationUrl}/api/notifications`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((response) => response.json())
            .then((data) => {
            if (data != null && data.length > 0) {
                setFetchedNotifications(data);
            }
        });
    };
    const redirectAndClose = () => {
        setIsNotificationsOpen(false);
        redirectTo(applicationSpaRoutes.notifications);
    };
    const handleClick = (id) => {
        var _a;
        if ((_a = notifications === null || notifications === void 0 ? void 0 : notifications.find((notification) => notification.id === id)) === null || _a === void 0 ? void 0 : _a.is_read) {
            redirectAndClose();
            return;
        }
        fetch(`${applicationUrl}/api/notifications/mark-as-read/${id}`, {
            method: 'POST',
            credentials: 'include'
        }).then(() => {
            const updatedNotifications = fetchedNotifications.map((notification) => (notification.id === id ? Object.assign(Object.assign({}, notification), { is_read: true }) : notification));
            setFetchedNotifications(updatedNotifications);
            redirectAndClose();
            if (onNotificationsUpdate != null)
                onNotificationsUpdate();
        });
    };
    useEffect(() => {
        if (onNotificationsUpdate == null) {
            fetchNotifications();
        }
    }, [onNotificationsUpdate]);
    useEffect(() => {
        if (notifications != null && notifications.length > 0) {
            setFetchedNotifications(notifications);
        }
    }, [notifications]);
    const notificationContent = (notification) => (_jsxs("li", Object.assign({ onClick: () => handleClick(notification.id), "aria-hidden": "true", className: cx(styles.notificationListItem, { [styles.unreadNotification]: !notification.is_read }) }, { children: [_jsx("div", { className: styles.dot }), _jsx("div", { className: styles.content, dangerouslySetInnerHTML: { __html: notification.content } })] }), notification.id));
    return (_jsxs(_Fragment, { children: [_jsxs("button", Object.assign({ type: "button", className: styles.notificationBellButton, "data-cy": "notification-button", onClick: () => setIsNotificationsOpen(!isNotificationsOpen), ref: ref, title: t('header.notificationBell.showMyNotifications') }, { children: [fetchedNotifications.some((notification) => !notification.is_read) ? _jsx("span", { className: styles.notificationRedDot }) : null, _jsx(FontAwesomeIcon, { className: styles.notificationBellIcon, icon: faBell })] })), isNotificationsOpen ? (_jsxs(Tooltip, Object.assign({ parentRef: ref, onClose: () => setIsNotificationsOpen((prevOpen) => !prevOpen), position: "bottom-center", isArrowVisible: true, isParentSticky: true, classNames: { wrapper: styles.tooltipWrapper } }, { children: [fetchedNotifications.length > 0 ? (_jsx("ul", Object.assign({ "data-cy": "header-notifications-list", className: styles.notificationUnorderedList }, { children: [...fetchedNotifications].sort((a, b) => ((a.is_read ? 1 : 0) > (b.is_read ? 1 : 0) ? 1 : -1)).slice(0, 3).map((notification) => (notificationContent(notification))) }))) : null, _jsx("div", Object.assign({ className: styles.bottomLinkWrapper }, { children: fetchedNotifications.length > 0 ? (_jsx("button", Object.assign({ type: "button", className: styles.readAllNotifications, onClick: redirectAndClose }, { children: t('header.notificationBell.seeAll') }))) : (_jsx("button", Object.assign({ type: "button", className: styles.noNotifications, onClick: () => setIsNotificationsOpen(false) }, { children: t('header.notificationBell.noNotifications') }))) }))] }))) : null] }));
};
export default HeaderNotifications;
