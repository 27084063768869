var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStorageItem, removeStorageItem } from '../../../../utils/storage';
import { validateFormPayload } from './Login.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Message from '../../../../components/Message';
import { SubmitActions, AuthenticationSteps, loginRedirectKey } from '../../constants';
import sharedStyles from '../../styles.module.scss';
import { faArrowLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
const Login = ({ onSwitchStep, onSetLoading = null, onSubmitCallback, onSubmitUserSelectCallback, apiUrl = '', email }) => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ email, password: '' });
    const [errors, setErrors] = useState([]);
    const [isSubmitErrorVisible, setSubmitErrorVisible] = useState(false);
    const handleSubmit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        if (isLoading)
            return;
        const formErrors = validateFormPayload(formData);
        if (formErrors.length > 0) {
            setErrors(formErrors);
            return;
        }
        setErrors([]);
        setLoading(true);
        setSubmitErrorVisible(false);
        if (onSetLoading != null)
            onSetLoading(true);
        try {
            const response = yield fetch(`${apiUrl}/api/user/authenticate`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
                credentials: 'include'
            });
            if (response.ok) {
                // Submit callback.
                if (onSubmitCallback != null) {
                    onSubmitCallback(response, SubmitActions.LOGIN);
                    return;
                }
                let redirectUrl = getStorageItem(loginRedirectKey);
                if (!redirectUrl) {
                    redirectUrl = yield response.json();
                }
                removeStorageItem(loginRedirectKey);
                // Fetch all the users connected to the current the account, and render select user view if there is more then one.
                if (onSubmitUserSelectCallback != null && onSwitchStep != null) {
                    const otherUsersRequest = yield fetch(`${apiUrl}/api/user/other-users`, { method: 'GET', credentials: 'include' });
                    if (otherUsersRequest.ok) {
                        const otherUsers = yield otherUsersRequest.json();
                        if (otherUsers.length > 0) {
                            setLoading(false);
                            onSwitchStep(AuthenticationSteps.USER_SELECT);
                            onSubmitUserSelectCallback({ users: otherUsers, redirectUrl });
                            return;
                        }
                    }
                }
                window.location.href = redirectUrl;
            }
            else {
                setSubmitErrorVisible(true);
                setLoading(false);
            }
            if (onSetLoading != null)
                onSetLoading(false);
        }
        catch (error) {
            setSubmitErrorVisible(true);
            setLoading(false);
        }
        if (onSetLoading != null)
            onSetLoading(false);
    });
    const submitErrorElement = isSubmitErrorVisible ? (_jsx("div", Object.assign({ className: sharedStyles.formItem }, { children: _jsx(Message, Object.assign({ "data-cy": "login-step-generic-error", type: "error" }, { children: t('global.errors.login') })) }))) : null;
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit, className: sharedStyles.form, "data-cy": "login-step" }, { children: [_jsx("h4", Object.assign({ className: sharedStyles.title }, { children: t('authentication.login.title') })), _jsx("div", Object.assign({ className: sharedStyles.formItem }, { children: _jsx(Input, { type: "email", name: "email", label: t('authentication.login.emailLabel'), value: formData.email, "data-cy": "login-step-email", rightIcon: _jsx(FontAwesomeIcon, { icon: faCheckCircle, className: sharedStyles.emailCheckIcon }), disabled: true }) })), _jsx("div", Object.assign({ className: sharedStyles.formItem }, { children: _jsx(Input, { type: "password", name: "password", autoComplete: "current-password", label: t('authentication.login.passwordLabel'), value: formData.password, onChange: (event) => setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { password: event.target.value }))), isError: errors.includes('password'), errorMessage: errors.includes('password') ? t('global.errors.password.empty') : null, "data-cy": "login-step-password", disabled: isLoading, autoFocus: true }) })), _jsx("button", Object.assign({ type: "button", onClick: (event) => {
                    event.preventDefault();
                    onSwitchStep(AuthenticationSteps.RESET_PASSWORD);
                }, "data-cy": "login-step-reset-password-button", className: sharedStyles.resetPasswordButton }, { children: t('authentication.login.forgotPassword') })), submitErrorElement, _jsxs("div", Object.assign({ className: sharedStyles.formNavigationButtons }, { children: [_jsx(Button, Object.assign({ onClick: () => onSwitchStep(AuthenticationSteps.EMAIL_VERIFICATION), kind: "text", size: "fluid", icon: _jsx(FontAwesomeIcon, { icon: faArrowLeft, className: sharedStyles.buttonIcon }), "data-cy": "login-step-back-button" }, { children: t('authentication.backCta') })), _jsx(Button, Object.assign({ type: "submit", color: "pink", isLoading: isLoading, "data-cy": "login-step-submit-button", size: "fluid" }, { children: t('authentication.login.submit') }))] }))] })));
};
export default Login;
