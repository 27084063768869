import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { createPortal } from 'react-dom';
import MenuButton from '../../HeaderElements/MenuButton';
import { HeaderContext } from '../../../Header.context';
import styles from './WordpressCategories.module.scss';
import cx from 'classnames';
import CategoryList from './components/CategoryList/CategoryList';
const WordpressCategories = ({ showAllCategories = false }) => {
    const { categories, handleClickCategory, openedMenuItem, headerRef, setOpenedMenuItem } = useContext(HeaderContext);
    let categoriesToRender = categories !== null && categories !== void 0 ? categories : [];
    if (!showAllCategories) {
        categoriesToRender = categoriesToRender.filter((category) => category.title === 'Produkter');
    }
    if (headerRef.current == null) {
        return null;
    }
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx("div", Object.assign({ className: styles.categories }, { children: categoriesToRender.map((category) => (_jsx(MenuButton, Object.assign({ hasChildItems: category.child_items != null && category.child_items.length > 0, onClick: () => handleClickCategory(category.title), onMouseEnter: () => setOpenedMenuItem(category.title), isOpen: category.title === openedMenuItem, "data-cy": `category-button-${category.ID}`, url: category.child_items == null ? category.url : null }, { children: category.title }), category.title))) })), openedMenuItem != null ? createPortal(_jsx("div", Object.assign({ role: "presentation", className: cx(styles.overlay, 'mt-16'), onClick: () => setOpenedMenuItem(null) }, { children: _jsx(CategoryList, {}) })), headerRef.current) : null] })));
};
export default WordpressCategories;
