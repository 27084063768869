import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import WordpressCategories from '../../../HeaderMenuItems/WordpressCategories';
import styles from './NotLoggedInHeader.module.scss';
import { HeaderContext } from '../../../../Header.context';
const NotLoggedInHeader = () => {
    const { t } = useTranslation();
    const { actions, setOpenedMenuItem } = useContext(HeaderContext);
    const { onClickLogin, onClickSignUp } = actions !== null && actions !== void 0 ? actions : {};
    const handleMouseEnter = () => {
        setOpenedMenuItem(null);
    };
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(WordpressCategories, { showAllCategories: true }), onClickLogin != null ? (_jsx("button", Object.assign({ type: "button", className: styles.loginButton, onClick: onClickLogin, "data-testid": "login-button", onMouseEnter: handleMouseEnter }, { children: _jsx("span", Object.assign({ className: styles.buttonContent }, { children: t('header.loginButton') })) }))) : null, onClickSignUp != null ? (_jsx("button", Object.assign({ type: "button", className: styles.signUpButton, onClick: onClickSignUp, "data-testid": "signup-button", onMouseEnter: handleMouseEnter }, { children: _jsx("span", Object.assign({ className: styles.buttonContent }, { children: t('header.signUpButton') })) }))) : null] })));
};
export default NotLoggedInHeader;
