import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef, useContext } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from '../../../../../hooks/useClickOutside';
import { useResponsiveness } from '../../../../../hooks/useResponsiveness';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDropdownCoordinates } from '../../../Header.utils';
import styles from './UserMenu.module.scss';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faRightFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { applicationSpaRoutes } from '../../../../../constants';
import { HeaderContext } from '../../../Header.context';
import MenuButton from '../../HeaderElements/MenuButton/MenuButton';
const UserMenu = () => {
    const { t } = useTranslation();
    const switchButtonRef = useRef(null);
    const listRef = useRef(null);
    const { redirectTo, urls, user, openedMenuItem, setOpenedMenuItem } = useContext(HeaderContext);
    const applicationUrl = urls === null || urls === void 0 ? void 0 : urls.application;
    const [otherUsers, setOtherUsers] = useState(null);
    const [dropdownStyle, setDropdownStyle] = useState({ top: 0, left: 0, width: 0 });
    const responsiveness = useResponsiveness();
    useClickOutside(listRef, () => setOpenedMenuItem(null), switchButtonRef);
    const handleUserMenu = () => {
        if (openedMenuItem === 'user-menu') {
            setOpenedMenuItem(null);
        }
        setOpenedMenuItem('user-menu');
    };
    const fetchOtherUsers = () => {
        fetch(`${applicationUrl}/api/user/other-users`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((data) => data.json())
            .then((data) => {
            if (data != null && data.length > 0) {
                setOtherUsers(data);
            }
        });
    };
    const handleClickMyProfile = () => {
        redirectTo(applicationSpaRoutes.profileEdit);
    };
    const handleClickSwitchUser = (id) => {
        window.location.href = `${applicationUrl}/user/switch/${id}?redirect_url=${window.location.href}`;
    };
    const logOutUser = () => {
        window.location.href = `${applicationUrl}/user/logout`;
    };
    const handleChangeOption = (value) => {
        if (value === 'my-profile') {
            handleClickMyProfile();
            return;
        }
        if (value === 'logout') {
            logOutUser();
            return;
        }
        handleClickSwitchUser(Number(value));
    };
    useEffect(() => {
        fetchOtherUsers();
    }, []);
    useEffect(() => {
        setDropdownStyle(getDropdownCoordinates(switchButtonRef));
    }, [openedMenuItem]);
    const dropdownDesktopElement = openedMenuItem === 'user-menu' ? (_jsxs("ul", Object.assign({ className: styles.menu, ref: listRef, style: dropdownStyle, "data-cy": "user-menu-list", onMouseLeave: () => setOpenedMenuItem(null) }, { children: [_jsxs("li", Object.assign({ role: "presentation", className: styles.menuItem, onClick: handleClickMyProfile }, { children: [_jsx(FontAwesomeIcon, { icon: faUser, className: styles.icon }), _jsx("p", { children: t('header.userMenu') })] })), otherUsers === null || otherUsers === void 0 ? void 0 : otherUsers.map((otherUser) => (_jsxs("li", Object.assign({ role: "presentation", onClick: () => handleClickSwitchUser(otherUser.id), className: styles.menuItem }, { children: [_jsx(FontAwesomeIcon, { icon: faUser, className: styles.icon }), _jsx("p", { children: otherUser.fullname })] }), otherUser.id))), _jsxs("li", Object.assign({ role: "presentation", onClick: () => logOutUser(), className: styles.menuItem }, { children: [_jsx(FontAwesomeIcon, { icon: faRightFromBracket, className: styles.icon }), _jsx("p", { children: t('header.logOut') })] }))] }))) : null;
    return responsiveness.above.md ? (_jsxs(MenuButton, Object.assign({ title: t('header.userMenu'), ref: switchButtonRef, onClick: handleUserMenu, onMouseEnter: handleUserMenu, "data-cy": "user-menu-button", hasChildItems: true }, { children: [_jsx(FontAwesomeIcon, { icon: faUser, className: styles.userIcon }), '  ', _jsx("span", Object.assign({ className: styles.buttonContent }, { children: user === null || user === void 0 ? void 0 : user.fullname })), createPortal(dropdownDesktopElement, document.body)] }))) : (_jsxs("div", Object.assign({ className: styles.mobileMenu, "data-cy": "mobile-user-menu-list" }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: "userSelect" }, { children: [_jsx(FontAwesomeIcon, { icon: faUser, className: styles.icon }), _jsx("p", Object.assign({ className: styles.content }, { children: user === null || user === void 0 ? void 0 : user.fullname })), _jsx(FontAwesomeIcon, { icon: faChevronDown, className: styles.icon })] })), _jsxs("select", Object.assign({ id: "userSelect", onChange: (event) => handleChangeOption(event.target.value), "data-cy": "mobile-user-select" }, { children: [_jsx("option", { children: user === null || user === void 0 ? void 0 : user.fullname }), _jsx("option", Object.assign({ value: "my-profile" }, { children: t('header.userMenu') })), otherUsers === null || otherUsers === void 0 ? void 0 : otherUsers.map((otherUser) => (_jsx("option", Object.assign({ value: otherUser.id }, { children: otherUser.fullname })))), _jsx("option", Object.assign({ value: "logout" }, { children: t('header.logOut') }))] }))] })));
};
export default UserMenu;
