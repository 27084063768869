import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthenticationModalData } from '@store/slices/modalsSlice';
import { useGetUserDetailsQuery } from '@store/api/user';
import { useGetBasketQuery } from '@store/api/basket';
import { setPurchaseSidebarOpen } from '@store/slices/sidebarSlice';
import HeaderFeature from '@frontend-components/features/Header';
import { AuthenticationSteps } from '@frontend-components/features/Authentication';
import { applicationUrl } from '@constants';
const Header = () => {
    const dispatch = useDispatch();
    const { data: userDetails, isLoading: isUserDetailsLoading } = useGetUserDetailsQuery();
    const { data: basketData } = useGetBasketQuery(null, { skip: (userDetails === null || userDetails === void 0 ? void 0 : userDetails.id) == null });
    const handleClickOpenAuthModal = useCallback((step) => {
        dispatch(setAuthenticationModalData({ step }));
    }, []);
    return (_jsx(HeaderFeature, { user: userDetails, isUserLoading: isUserDetailsLoading, basketProductsCount: (basketData === null || basketData === void 0 ? void 0 : basketData.items) != null ? basketData.items.length : 0, redirectTo: (url) => {
            window.location.href = url;
        }, actions: {
            onClickLogin: () => handleClickOpenAuthModal(AuthenticationSteps.EMAIL_VERIFICATION),
            onClickSignUp: () => handleClickOpenAuthModal(AuthenticationSteps.EMAIL_VERIFICATION),
            onClickBasket: () => dispatch(setPurchaseSidebarOpen(true))
        }, urls: {
            application: applicationUrl,
            frontend: '',
            applicationSpa: applicationUrl
        } }));
};
export default Header;
