import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import styles from './BasketButton.module.scss';
import { faBasketShopping } from '@fortawesome/pro-regular-svg-icons';
import { HeaderContext } from '../../../Header.context';
const BasketButton = () => {
    const { t } = useTranslation();
    const { basketProductsCount = 0, actions } = useContext(HeaderContext);
    const { onClickBasket } = actions !== null && actions !== void 0 ? actions : {};
    return (_jsxs("button", Object.assign({ type: "button", id: "toggle-basket", onClick: onClickBasket, title: t('basket.buttonTitle'), "data-testid": "purchase-sidebar-toggle-button", className: styles.button }, { children: [basketProductsCount > 0 ? _jsx("span", Object.assign({ className: styles.number }, { children: basketProductsCount })) : null, _jsx(FontAwesomeIcon, { className: styles.icon, icon: faBasketShopping })] })));
};
export default BasketButton;
