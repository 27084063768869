import { isValidName, isValidPassword, isValidPhoneNumber } from '../../../../utils/validation';
export function validateForm(userData) {
    const validationErrors = [];
    if (!isValidName(userData.firstName)) {
        validationErrors.push('firstName');
    }
    if (!isValidName(userData.lastName)) {
        validationErrors.push('lastName');
    }
    if (!isValidPhoneNumber(userData.phone)) {
        validationErrors.push('phone');
    }
    if (!isValidPassword(userData.password)) {
        validationErrors.push('password');
    }
    return validationErrors;
}
