import { combineReducers } from '@reduxjs/toolkit';
import { USER_API_REDUCER_KEY, userApi } from '@store/api/user';
import { PAYMENT_API_REDUCER_KEY, paymentApi } from '@store/api/payment';
import { PRODUCTS_API_REDUCER_KEY, productsApi } from '@store/api/products';
import { BASKET_API_REDUCER_KEY, basketApi } from '@store/api/basket';
import { PARTNER_API_REDUCER_KEY, partnerApi } from '@store/api/partner';
import modalReducer from './slices/modalsSlice';
import sidebarSlice from './slices/sidebarSlice';
const rootReducer = combineReducers({
    [USER_API_REDUCER_KEY]: userApi.reducer,
    [PAYMENT_API_REDUCER_KEY]: paymentApi.reducer,
    [PRODUCTS_API_REDUCER_KEY]: productsApi.reducer,
    [BASKET_API_REDUCER_KEY]: basketApi.reducer,
    [PARTNER_API_REDUCER_KEY]: partnerApi.reducer,
    modal: modalReducer,
    sidebar: sidebarSlice
});
export default rootReducer;
