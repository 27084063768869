var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import styles from './Message.module.scss';
const Message = (_a) => {
    var { type = 'message', className, children } = _a, props = __rest(_a, ["type", "className", "children"]);
    return (_jsx("p", Object.assign({}, props, { className: classnames(styles.message, className, { [styles.error]: type === 'error' }) }, { children: children })));
};
export default Message;
